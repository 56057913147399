import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  FileInput,
  FileField,
} from 'react-admin';

export const LegalDocumentCreate = (props) => {
  const [documentFile, setDocumentFile] = useState('');

  const onDocumentChange = (event) => {
    setDocumentFile(event);
  };

  return (
    <Create title="Documentos Legais - Create" {...props}>
      <SimpleForm redirect="list">
        <TextInput label="Nome" source="name" />
        <FileInput
          label="Arquivo"
          name="file"
          accept=".pdf"
          source="file"
          multiple={false}
          onChange={onDocumentChange}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput label="Link" source="url" disabled={documentFile !== ''} />
        <BooleanInput label="Exibir" source="isEnabled" defaultValue={false} />
      </SimpleForm>
    </Create>
  );
};
