import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import gql from 'graphql-tag';
import { isNil, authStorage, uniqueId } from '@ampli/utils';

const authLogin = _ref => {
  let {
    client,
    params
  } = _ref;
  const options = {
    mutation: gql`
      mutation Login($credentials: Credentials!) {
        loginAmpli(credentials: $credentials) {
          accessToken
          tokenType
          expiresIn
          scope
        }
      }
    `,
    variables: {
      credentials: params
    }
  };
  return client.mutate(options).then(_ref2 => {
    let {
      data
    } = _ref2;
    return authStorage.setItem('token', data.loginAmpli.accessToken);
  });
};

const authLogout = () => {
  authStorage.removeItem('token');
  authStorage.removeItem('permissions');
  localStorage.removeItem('permissions');
  return Promise.resolve();
};

const authError = _ref3 => {
  let {
    params = {}
  } = _ref3;
  const status = params.status || (params.info || {}).status;

  if (status === 401 || status === 403) {
    const error = new Error(`Unauthorized with status ${status}.`);
    console.error(error);
    authLogout();
    return Promise.reject(error);
  }

  return Promise.resolve();
};

const authCheck = async _ref4 => {
  let {
    client
  } = _ref4;
  const token = await authStorage.getItem('token');

  if (!token) {
    authLogout();
    return Promise.reject({
      redirectTo: '/login'
    });
  }

  const options = {
    query: gql`
      query CheckToken {
        me {
          id
        }
      }
    `,
    fetchPolicy: 'no-cache'
  };
  return client.query(options).then(_ref5 => {
    let {
      errors
    } = _ref5;

    if (errors) {
      // TODO: map errors
      const error = new Error('Invalid token.');
      console.error(error);
      authLogout();
      throw error;
    } else {
      return token;
    }
  });
};

const authGetPermissions = async _ref6 => {
  let {
    client
  } = _ref6;
  const token = await authStorage.getItem('token');
  const permissions = await authStorage.getItem('permissions');

  if (token) {
    if (isNil(permissions)) {
      const options = {
        query: gql`
          query GetPermissions {
            me {
              grantedAuthorities
            }
          }
        `,
        fetchPolicy: 'no-cache'
      };
      return client.query(options).then(_ref7 => {
        let {
          data,
          errors
        } = _ref7;

        if (errors) {
          // TODO: map errors
          const error = new Error('Get permissions error.');
          console.error(error);
          authLogout();
          throw error;
        } else {
          localStorage.setItem('permissions', JSON.stringify(data.me) || {});
          authStorage.setItem('permissions', data.me || {});

          if (window.location.pathname.includes('/operacoes')) {
            return window.location.reload();
          }

          return null;
        }
      });
    }

    return Promise.resolve(permissions);
  }

  return Promise.resolve({});
};

const auth = {
  [AUTH_LOGIN]: authLogin,
  [AUTH_LOGOUT]: authLogout,
  [AUTH_ERROR]: authError,
  [AUTH_CHECK]: authCheck,
  [AUTH_GET_PERMISSIONS]: authGetPermissions
};
export const authProviderCreator = _ref8 => {
  let {
    client
  } = _ref8;
  return (type, params) => {
    const queryId = uniqueId(`auth ${type} `);

    try {
      console.info(queryId, {
        params
      });
      return auth[type]({
        client,
        type,
        params,
        queryId
      }).then(response => {
        console.debug(queryId, {
          response
        });
        return response;
      }).catch(error => {
        console.debug(queryId, {
          error
        });
        throw error;
      });
    } catch (error) {
      console.debug(queryId, {
        error
      });
      throw new Error(`Unsupported provider: Auth:${type}`);
    }
  };
};