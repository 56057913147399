import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  RadioButtonGroupInput,
  CheckboxGroupInput,
  required,
} from 'react-admin';
import { SelectImageInput } from '@ampli/admin-core';
import { makeStyles } from '@material-ui/core/styles';
import { kebabCase } from 'lodash';
import { choicesType } from './common';

const useStyles = makeStyles(() => ({
  linkContent: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonText: {
    color: 'gray',
    margin: '0 10px 0px 0px',
  },
}));

export const ProductOfferCreate = (props) => {
  const styles = useStyles();
  const [filter, setFilter] = useState({ tags: '' });
  const [isExternalUrl, setIsExternalUrl] = useState(false);
  const [internalLink, setInternalLink] = useState(
    'https://ava.ampli.com.br/produtos/'
  );
  const [showInCourses, setShowInCourses] = useState(false);
  const [useNewsIn, setUseNewsIn] = useState(false);

  const onChangeFilterImage = (value) => {
    setFilter({ tags: value });
  };

  const onNewsNameChange = (event) => {
    setInternalLink(
      `https://ava.ampli.com.br/produtos/${kebabCase(event.target.value)}`
    );
  };
  const onShowAtChange = (event) => {
    if (event.target.value === 'COURSE') return setShowInCourses(true);
    setShowInCourses(false);
  };

  const onUseInChange = (value) => {
    setUseNewsIn(value);
  };

  return (
    <Create title="Novidades - Create" {...props}>
      <SimpleForm redirect="list">
        <TextInput onChange={onNewsNameChange} label="Título" source="name" />
        <TextInput label="Texto 1" source="firstDescription" />
        <TextInput label="Texto 2" source="secondDescription" />
        <TextInput label="Texto 3" source="thirdDescription" />
        <TextInput label="CTA" source="cta" />
        <div className={styles.linkContent}>
          <p className={styles.buttonText}>É uma URL externa?</p>
          <input
            type="checkbox"
            onChange={() => setIsExternalUrl(!isExternalUrl)}
            value={isExternalUrl}
          />
        </div>
        {isExternalUrl ? (
          <TextInput label="Link" source="urlWebPage" />
        ) : (
          <TextInput
            disabled
            label="Link"
            source="urlWebPage"
            format={() => internalLink}
            initialValue={internalLink}
          />
        )}
        <ReferenceArrayInput
          label="Cursos para Matricula"
          source="courseIds"
          reference="course"
        >
          <AutocompleteArrayInput source="name" optionText="name" />
        </ReferenceArrayInput>
        <ReferenceInput
          filter={filter}
          label="Logo de Novidade"
          source="imageId"
          reference="image"
          perPage={100}
        >
          <SelectImageInput onFilterChange={onChangeFilterImage} />
        </ReferenceInput>
        <ArrayInput label="Tags" source="tags">
          <SimpleFormIterator>
            <TextInput label="Tag" />
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          label="Exibir em"
          optionText="name"
          optionValue="value"
          source="displayedIn"
          choices={choicesType.displayedIn}
          onChange={onShowAtChange}
        />
        {showInCourses && (
          <>
            <RadioButtonGroupInput
              source="useIn"
              label="Aplicar em"
              choices={choicesType.useIn}
              onChange={onUseInChange}
              validate={required()}
            />
            {(useNewsIn === 'BOTH_CHOICES' ||
              useNewsIn === 'TYPE_OF_COURSE') && (
              <CheckboxGroupInput
                label="Tipo de curso"
                source="courseDegrees"
                choices={choicesType.courseDegrees}
                validate={required()}
              />
            )}
            {(useNewsIn === 'BOTH_CHOICES' || useNewsIn === 'COURSE') && (
              <ReferenceArrayInput
                label="Aplicar nos cursos"
                source="displayedInCourses"
                reference="course"
                validate={required()}
              >
                <AutocompleteArrayInput source="name" optionText="name" />
              </ReferenceArrayInput>
            )}
          </>
        )}
      </SimpleForm>
    </Create>
  );
};
