import { isString } from '@ampli/utils';
export { name, version } from '../../package.json';

export const ampli =
  window.__AMPLI__ || JSON.parse(process.env.REACT_APP_AMPLI);

export const graphqlUrl = ampli.graphqlUrl[ampli.squad] || ampli.graphqlUrl;

if (!isString(graphqlUrl)) {
  console.debug({ ampli, graphqlUrl });
  throw new Error('GraphQL URL must be a string.');
}

export const isProduction = ampli.env === 'production';

export const logLevel = isProduction ? 'error' : 'debug';

export const tinyMceApiKey = 'j5d6y9evysfmpbx4ma4wgpabv4nw5ux7jwvzuhnz648gzz25';

export const sentryDsn =
  'https://df1dc86b7a684e8ab7ffbf49b0354185@sentry.io/5187952';
