import React, { useState } from 'react';
import {
  Edit,
  BooleanInput,
  SimpleForm,
  TextInput,
  RadioButtonGroupInput,
  required,
  FileInput,
  FileField,
  TextField,
} from 'react-admin';
import { courseDegrees } from './common';

export const CourseCategoryEdit = (props) => {
  const [newDocumentFile, setNewDocumentFile] = useState('');
  const [newUrl, setNewUrl] = useState('iconLink');

  const onDocumentChange = (event) => {
    setNewDocumentFile(event);
    setNewUrl(' ');
  };

  return (
    <Edit title="Edição de Categorias de Curso" {...props}>
      <SimpleForm redirect="list">
        <TextInput
          label="Nome da Categoria"
          source="name"
          validate={required()}
        />
        <BooleanInput label="Exibir" source="visible" />
        <BooleanInput label="Categoria Principal" source="main" />
        <RadioButtonGroupInput
          source="courseDegree"
          label="Nível de curso"
          choices={courseDegrees}
          validate={required()}
        />
        <FileInput
          label="Substituir arquivo"
          name="icon"
          accept=".svg"
          source="file"
          multiple={false}
          onChange={onDocumentChange}
        >
          <FileField source="src" title="title" />
        </FileInput>
        {newDocumentFile === '' ? (
          <TextField label="Icone atual" source={newUrl} />
        ) : null}
      </SimpleForm>
    </Edit>
  );
};
