import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export const defaultTinyMceMenu = {
  file: {
    title: 'File',
    items: 'newdocument restoredraft | preview | print '
  },
  edit: {
    title: 'Edit',
    items: 'undo redo | cut copy paste | selectall | searchreplace'
  }
};
export const tinyMceMenu = _objectSpread(_objectSpread({}, defaultTinyMceMenu), {}, {
  view: {
    title: 'View',
    items: 'code | visualaid visualchars visualblocks | spellchecker | ' + 'preview fullscreen'
  },
  insert: {
    title: 'Insert',
    items: 'image link media template codesample inserttable | ' + 'charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'
  },
  format: {
    title: 'Format',
    items: 'bold italic underline strikethrough superscript subscript codeformat | ' + 'formats blockformats align | forecolor backcolor | removeformat'
  },
  tools: {
    title: 'Tools',
    items: 'spellchecker spellcheckerlanguage | code wordcount'
  },
  help: {
    title: 'Help',
    items: 'help'
  }
});