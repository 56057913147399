import React, { useState } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  CheckboxGroupInput,
  SelectInput,
  required,
  FormDataConsumer,
  RadioButtonGroupInput,
} from 'react-admin';
import { SelectImageInput } from '@ampli/admin-core';
import { choicesType } from './common';

export const ProductOfferEdit = (props) => {
  const [filter, setFilter] = useState({ tags: '' });
  const [showInCourses, setShowInCourses] = useState(false);
  const [initialValidation, setInitialValidation] = useState(false);
  const [useNewsIn, setUseNewsIn] = useState(false);

  const onChangeFilterImage = (value) => {
    setFilter({ tags: value });
  };

  const onShowAtChange = (event) => {
    if (event.target.value === 'COURSE') return setShowInCourses(true);
    setShowInCourses(false);
  };

  const onUseInChange = (value) => {
    setUseNewsIn(value);
  };

  const validateDisplayInValue = (record) => {
    if (record.displayedIn === 'COURSE' && !initialValidation) {
      setInitialValidation(true);
      setShowInCourses(true);
      setUseNewsIn(record.useIn);
    }
    return true;
  };

  return (
    <Edit title="Novidades - Edit" undoable={false} {...props}>
      <SimpleForm redirect="list">
        <TextInput disabled source="id" label="ID" />
        <TextInput label="Título" source="name" />
        <TextInput label="Texto 1" source="firstDescription" />
        <TextInput label="Texto 2" source="secondDescription" />
        <TextInput label="Texto 3" source="thirdDescription" />
        <TextInput label="CTA" source="cta" />
        <TextInput label="Link" source="urlWebPage" />
        <ReferenceArrayInput
          label="Cursos"
          source="courseIds"
          reference="course"
        >
          <AutocompleteArrayInput source="name" optionText="name" />
        </ReferenceArrayInput>
        <ReferenceInput
          filter={filter}
          label="Logo de Novidade"
          source="imageId"
          reference="image"
          perPage={100}
        >
          <SelectImageInput onFilterChange={onChangeFilterImage} />
        </ReferenceInput>
        <ArrayInput label="Tags" source="tags">
          <SimpleFormIterator>
            <TextInput label="Tag" />
          </SimpleFormIterator>
        </ArrayInput>
        <FormDataConsumer>
          {({ record }) =>
            validateDisplayInValue(record) && (
              <SelectInput
                label="Exibir em"
                optionText="name"
                optionValue="value"
                source="displayedIn"
                choices={choicesType.displayedIn}
                onChange={onShowAtChange}
                validate={required()}
              />
            )
          }
        </FormDataConsumer>
        {showInCourses && (
          <>
            <RadioButtonGroupInput
              source="useIn"
              label="Aplicar em"
              choices={choicesType.useIn}
              onChange={onUseInChange}
              validate={required()}
            />
            {(useNewsIn === 'BOTH_CHOICES' ||
              useNewsIn === 'TYPE_OF_COURSE') && (
              <CheckboxGroupInput
                label="Tipo de curso"
                source="courseDegrees"
                choices={choicesType.courseDegrees}
                validate={required()}
              />
            )}
            {(useNewsIn === 'BOTH_CHOICES' || useNewsIn === 'COURSE') && (
              <ReferenceArrayInput
                label="Aplicar nos cursos"
                source="displayedInCourses"
                reference="course"
                validate={required()}
              >
                <AutocompleteArrayInput source="name" optionText="name" />
              </ReferenceArrayInput>
            )}
          </>
        )}
      </SimpleForm>
    </Edit>
  );
};
