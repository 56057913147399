import { css } from '@emotion/css';
import { px2rem, getColor, mediaQuery } from "../../utils";
export const timerComponentContent = onClick => css`
  width: 100%;
  background: ${getColor('semantic-alert')};
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: ${px2rem(60)};
  ${onClick && 'cursor: pointer;'}
  ${mediaQuery('mobile')} {
    flex-direction: column;
    height: auto;
  }
`;
export const timerContent = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${px2rem(8)} ${px2rem(14)};
  ${mediaQuery('mobile')} {
    margin: ${px2rem(0)} ${px2rem(14)};
  }
`;
export const timeText = fontSize => css`
  font-weight: 400;
  text-align: center;
  color: black;
  height: ${px2rem(22)};
  font-size: ${fontSize};
`;
export const timeTitle = css`
  font-weight: 400;
  text-align: center;
  color: black;
  font-size: ${px2rem(20)};
  margin: ${px2rem(12)} 0px;
  ${mediaQuery('mobile')} {
    margin: ${px2rem(8)} 0px 0px 0px;
  }
`;
export const timeContent = css`
  display: flex;
  align-items: center;
  ${mediaQuery('mobile')} {
    margin-bottom: ${px2rem(8)};
  }
`;
export const seeMoreButton = css`
  font-weight: 700;
  font-size ${px2rem(16)};
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${px2rem(10)};
  border: 1px solid black;
  border-radius: 4px;
  padding: ${px2rem(6)} ${px2rem(8)} ${px2rem(6)} ${px2rem(12)};
  svg {
    width: ${px2rem(20)};
    height: ${px2rem(20)};
  }

  ${mediaQuery('mobile')} {
    display: none;
  }
`;