import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { validation } from './common';

export const SellerRegionalCreate = (props) => (
  <Create title="Regional dos Vendedores - Inserir" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nome" source="name" validate={validation.name} />
      <TextInput
        label="Descrição"
        source="description"
        validate={validation.description}
      />
    </SimpleForm>
  </Create>
);
