import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { tinyMceColorMap, tinyMceMenu, tinyMcePlugins, tinyMceToolbar, defaultTinyMceMenu } from './index';
const codeStyles = {
  'background-color': '#ededed',
  'border-radius': '4px',
  color: '#161616',
  'font-family': 'monospace'
};
const defaultTinyMceInit = {
  color_map: tinyMceColorMap,
  color_cols: 6,
  autoresize_bottom_margin: 0,
  menubar: true,
  invalid_styles: 'font-size',
  font_formats: '',
  fontsize_formats: ''
};
export const tinyMceInit = _objectSpread(_objectSpread({}, defaultTinyMceInit), {}, {
  menu: tinyMceMenu,
  plugins: tinyMcePlugins,
  toolbar: tinyMceToolbar,
  formats: {
    block_code: {
      block: 'div',
      styles: _objectSpread(_objectSpread({}, codeStyles), {}, {
        'margin-bottom': '16px',
        'overflow-x': 'scroll',
        padding: '16px',
        'white-space': 'nowrap'
      })
    },
    code: {
      inline: 'code',
      styles: _objectSpread(_objectSpread({}, codeStyles), {}, {
        padding: '4px'
      })
    }
  },
  style_formats_merge: true,
  style_formats: [{
    title: 'Block Code',
    format: 'block_code'
  }, {
    title: 'Inline Code',
    format: 'code'
  }]
});
export const tinyMceInitFormula = _objectSpread(_objectSpread({}, defaultTinyMceInit), {}, {
  toolbar: `undo redo | tiny_mce_wiris_formulaEditor`,
  menu: _objectSpread(_objectSpread({}, defaultTinyMceMenu), {}, {
    format: {
      title: 'Format',
      items: 'superscript subscript | removeformat'
    }
  }),
  autoformat: true,
  external_plugins: {
    tiny_mce_wiris: `${window.location.href}@wiris/mathtype-tinymce5/plugin.min.js`
  }
});
export const tinyMceInitTable = _objectSpread(_objectSpread({}, defaultTinyMceInit), {}, {
  toolbar: 'undo redo | alignleft aligncenter alignright alignjustify | removeformat',
  plugins: 'table',
  menu: _objectSpread(_objectSpread({}, defaultTinyMceMenu), {}, {
    insert: {
      title: 'Insert',
      items: ''
    },
    view: {
      title: 'View',
      items: ''
    },
    format: {
      title: 'Format',
      items: 'bold italic underline strikethrough superscript subscript | ' + 'formats blockformats align | removeformat'
    },
    table: {
      title: 'Table',
      items: 'inserttable | cell row column | tableprops deletetable'
    }
  }),
  table_advtab: false
});