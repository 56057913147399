import { getGlobal } from "./";
const navigator = getGlobal().navigator;
const isSSR = typeof navigator === 'undefined';
export const GEOLOCATION_NOT_SUPPORTED_CODE = 'GEO_001';
export const getCurrentLocation = async (callback, onError) => {
  if (isSSR) {
    return null;
  } else if (navigator && !navigator.geolocation) {
    throw new Error(`${GEOLOCATION_NOT_SUPPORTED_CODE}: Geolocation is not supported`);
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(callback || resolve, onError || reject);
  });
};