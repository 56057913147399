// THIS PACKAGE IS DEPRECATED!!
// Please refer to @ampli/icon for all future icon updates
import Calendar from "./calendar";
import Speed from "./speed";
import PhonePlay from "./phone-play";
import Message from "./message";
import HatGraduation from "./hat-graduation";
import Check from "./check";
import Facebook from "./facebook";
import Instagram from "./instagram";
import LinkedIn from "./linkedin";
import Mail from "./mail";
import Phone from "./phone";
import MapPin from "./map-pin";
import List from "./list";
import X from "./x";
import AlertCircle from "./alert-circle";
import AlertCircleBold from "./alert-circle-bold";
import CheckCircle from "./check-circle";
import Radio from "./radio";
import InfoCircleBold from "./info-circle-bold";
import Checkbox from "./checkbox";
import Clock from "./clock";
import MessageCircle from "./message-circle";
import CalendarSimple from "./calendar-simple";
import Star from "./star";
import User from "./user";
import Home from "./home";
import Paper from "./paper";
import School from "./school";
import Whatsapp from "./whatsapp";
import Document from "./document";
import UncheckedCircle from "./unchecked-circle";
import DoNotDisturb from "./do-not-disturb";
import Iphone from "./iphone";
import Heart from "./heart";
import Write from "./write";
import Vector from "./vector";
import Place from "./place";
import Chat from "./chat";
import Wifi from "./wifi";
import Library from "./library";
import Money from "./money";
import Bell from "./bell";
import SmartPhone from "./smartphone";
import DownloadCloud from "./download-cloud";
import Certificate from "./certificate";
import Award from "./award";
import ArrowDown from "./arrow-down";
import ArrowUp from "./arrow-up";
import SeatedWoman from "./seated-woman";
import SeatedWomanBig from "./seated-woman-big";
import ArrowLeft from "./arrow-left";
import Dot from "./dot";
import Eye from "./eye";
import Folder from "./folder";
import DollarSign from "./dollar-sign";
import SpeechBalloon from "./speech-balloon";
import GreaterThan from "./greater-than";
import LowerThan from "./lower-than";
import ArrowRight from "./arrow-right";
import ArrowLineRight from "./arrow-line-right";
import PersonReading from "./person-reading";
import Pen from "./pen";
import FileText from "./file-text";
import Pdf from "./pdf";
import ArrowDownCircle from "./arrow-down-circle";
import Objectives from "./objectives";
import Introduction from "./introduction";
import Content from "./content";
import One from "./one";
import Two from "./two";
import Three from "./three";
import Four from "./four";
import Five from "./five";
import Six from "./six";
import HelpBallon from "./help-ballon";
import FontSize from "./font-size";
import DoubleGreaterThan from "./double-greater-than";
import DoubleLowerThan from "./double-lower-than";
import Text from "./text";
import Video from "./video";
import ArrowLeftCircle from "./arrow-left-circle";
import ArrowRightCircle from "./arrow-right-circle";
import PlayVideo from "./play-video";
import PauseVideo from "./pause-video";
import Volume from "./volume";
import VolumeMuted from "./volume-muted";
import Fullscreen from "./fullscreen";
import Subtitle from "./subtitle";
import PlaybackRate from "./playback-rate";
import More from "./more";
import PersonDocument from "./person-document";
import Upload from "./upload";
import Camera from "./camera";
import PaperCertificate from "./paper-certificate";
import InfoCircle from "./info-circle";
import Organogram from "./organogram";
import PlaceFilled from "./place-filled";
import Lightning from "./lightning";
import Trash from "./trash";
import Help from "./help";
import CircleBarcode from "./circle-barcode";
import HighlightedList from "./highlighted-list";
import PercentageBarcode from "./percentage-barcode";
import Wallet from "./wallet";
import Copy from "./copy";
import Printer from "./printer";
import Share from "./share";
import Recurring from "./recurring";
import CheckboxOverflow from "./checkbox-overflow";
import Leave from "./leave";
import ClockAlt from "./clock-alt";
import PointUp from "./point-up";
import Refresh from "./refresh";
import Trophy from "./trophy";
import SadBook from "./sad-book";
import NoAttachments from "./no-attachments";
import CheckedCircle from "./checked-circle";
import OpenBook from "./open-book";
import Menu from "./menu";
import ChevronRight from "./chevron-right";
import CertificateHorizontal from "./certificate-horizontal";
import GooglePlay from "./google-play";
import AppleStore from "./apple-store";
import SecuritySite from "./security-site";
import BookOpen from "./book-open";
import Calculator from "./calculator";
import Tasks from "./tasks";
import ThumbsUp from "./thumbs-up";
import Smile from "./smile";
import PhoneStar from "./phone-star";
import Search from "./search";
import ChevronLeft from "./chevron-left";
import WhatsappOutline from "./whatsapp-outline";
import YoutubeOutline from "./youtube-outline";
import LinkedinOutline from "./linkedin-outline";
import TwitterOutline from "./twitter-outline";
import StarOutline from "./star-outline";
import Attendant from "./attendant";
import LaptopChat from "./laptop-chat";
import LaptopSmartphone from "./laptop-smartphone";
import PigBank from "./pig-bank";
import ThumbsUpBalloon from "./thumbs-up-balloon";
import Notes from "./notes";
import PlayCircle from "./play-circle";
import DoubleChevron from "./double-chevron";
import PlusIcon from "./plus-icon";
import HoldingPhone from "./holding-phone";
import ReceivingMessages from "./receiving-messages";
import WatchingVideo from "./watching-video";
import Coupon from "./coupon";
import Add from "./add";
import Lock from "./lock";
import Timer from "./timer";
import CheckCircleAlt from "./check-circle-alt";
import MenuBook from "./menu-book";
import RateStar from "./rate-star";
import BoldCheck from "./bold-check";
import NoHatGraduation from "./no-hat-graduation";
import LeftArrow from "./left-arrow";
import PlayDisabled from "./play-disabled";
import Cobweb from "./cobweb";
export const Icon = {
  Calendar,
  Cobweb,
  Speed,
  PhonePlay,
  Message,
  HatGraduation,
  Check,
  Facebook,
  Instagram,
  LinkedIn,
  Mail,
  Phone,
  MapPin,
  List,
  X,
  AlertCircle,
  AlertCircleBold,
  CheckCircle,
  Radio,
  InfoCircleBold,
  Checkbox,
  Clock,
  MessageCircle,
  CalendarSimple,
  Star,
  User,
  Home,
  Paper,
  School,
  Whatsapp,
  Document,
  UncheckedCircle,
  DoNotDisturb,
  Iphone,
  Heart,
  Write,
  Vector,
  Place,
  Chat,
  Wifi,
  Library,
  Money,
  Bell,
  SmartPhone,
  DownloadCloud,
  Award,
  Certificate,
  ArrowDown,
  ArrowUp,
  SeatedWoman,
  SeatedWomanBig,
  ArrowLeft,
  Dot,
  Eye,
  Folder,
  DollarSign,
  SpeechBalloon,
  GreaterThan,
  LowerThan,
  ArrowLineRight,
  ArrowRight,
  PersonReading,
  Pen,
  FileText,
  Pdf,
  ArrowDownCircle,
  Objectives,
  Introduction,
  Content,
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  HelpBallon,
  FontSize,
  DoubleLowerThan,
  DoubleGreaterThan,
  Text,
  Video,
  ArrowRightCircle,
  ArrowLeftCircle,
  PlayVideo,
  PauseVideo,
  Volume,
  VolumeMuted,
  Fullscreen,
  Subtitle,
  PlaybackRate,
  More,
  PersonDocument,
  Upload,
  Camera,
  PaperCertificate,
  InfoCircle,
  Organogram,
  PlaceFilled,
  Lightning,
  Trash,
  Help,
  CircleBarcode,
  HighlightedList,
  PercentageBarcode,
  Wallet,
  Copy,
  Printer,
  Share,
  Recurring,
  CheckboxOverflow,
  Leave,
  ClockAlt,
  PointUp,
  Refresh,
  Trophy,
  SadBook,
  NoAttachments,
  CheckedCircle,
  OpenBook,
  Menu,
  ChevronRight,
  CertificateHorizontal,
  GooglePlay,
  AppleStore,
  SecuritySite,
  BookOpen,
  Calculator,
  Tasks,
  ThumbsUp,
  Smile,
  PhoneStar,
  Search,
  ChevronLeft,
  WhatsappOutline,
  YoutubeOutline,
  LinkedinOutline,
  TwitterOutline,
  StarOutline,
  Attendant,
  LaptopChat,
  LaptopSmartphone,
  PigBank,
  ThumbsUpBalloon,
  Notes,
  PlayCircle,
  DoubleChevron,
  PlusIcon,
  HoldingPhone,
  ReceivingMessages,
  WatchingVideo,
  Coupon,
  Add,
  Lock,
  Timer,
  CheckCircleAlt,
  MenuBook,
  RateStar,
  BoldCheck,
  NoHatGraduation,
  LeftArrow,
  PlayDisabled
};