export const choicesType = {
  courseDegrees: [
    {
      id: 'GRADUATE',
      name: 'Pós Graduação',
    },
    {
      id: 'UNDERGRADUATE',
      name: 'Graduação',
    },
    {
      id: 'EJA',
      name: 'Eja',
    },
  ],
  useIn: [
    {
      id: 'TYPE_OF_COURSE',
      name: 'Tipo de curso',
    },
    {
      id: 'COURSE',
      name: 'Curso',
    },
    {
      id: 'BOTH_CHOICES',
      name: 'Ambos',
    },
    {
      id: 'ALL_COURSES',
      name: 'Todos os cursos',
    },
  ],
  displayedIn: [
    {
      value: 'MY_COURSES',
      name: 'Meus cursos',
    },
    {
      value: 'COURSE',
      name: 'Curso',
    },
  ],
};

export const DISPLAY_IN_VALUES = [
  { id: 'MY_COURSES', name: 'Meus Cursos' },
  { id: 'COURSE', name: 'Cursos' },
];
