import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as Yup from 'yup';

const isValidRecipient = () => ({
  name: 'isValidRecipient',
  test: value => {
    const isNumber = /^\d+$/.test(value.replace(/[^\d]+/g, ''));
    return isNumber ? Yup.string().matches(/\(\d\d\)\s9\d{4}-\d{4}/, 'Telefone inválido.') : Yup.string().email('E-mail inválido.');
  }
});

export const contactPermissionSchema = Yup.lazy(obj => Yup.object(Object.entries(obj).reduce((a, _ref) => {
  let [key] = _ref;
  return _objectSpread(_objectSpread({}, a), {}, {
    [key]: Yup.object().shape({
      recipient: Yup.string().test(isValidRecipient()),
      channel: Yup.string().oneOf(['EMAIL', 'SMS', 'WHATSAPP']),
      enabled: Yup.boolean(),
      context: Yup.string().oneOf(['NOTIFICATION_CONTEXT_MARKETING', 'NOTIFICATION_CONTEXT_CRITICAL', 'NOTIFICATION_CONTEXT_INTERNAL'])
    })
  });
}, {})));