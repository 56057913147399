import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { forEach } from 'lodash';
export const i18nCreator = _ref => {
  let {
    uses = [],
    init = {}
  } = _ref;
  i18n.use(Backend).use(LanguageDetector);
  forEach(uses, use => i18n.use(use));
  i18n.init(_objectSpread({
    fallbackLng: {
      'pt-BR': ['pt', 'en'],
      'pt-PT': ['pt', 'en'],
      default: ['en']
    },
    debug: false,
    interpolation: {
      escapeValue: false
    }
  }, init));
  return i18n;
};