import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { uniqueId } from '@ampli/utils';
import * as coreResources from '../resources';
export const dataProviderCreator = _ref => {
  let {
    client,
    resources
  } = _ref;
  return (type, resource, params) => {
    const queryId = uniqueId(`${resource} ${type} `);

    try {
      const allResources = _objectSpread(_objectSpread({}, coreResources), resources);

      console.info(queryId, {
        params
      });
      return allResources[resource][type]({
        client,
        params,
        queryId
      }).then(response => {
        console.debug(queryId, {
          response
        });
        return response;
      }).catch(error => {
        console.debug(queryId, {
          error
        });
        throw error;
      });
    } catch (error) {
      console.debug(queryId, {
        error
      });
      throw new Error(`Unsupported provider: ${resource}:${type}`);
    }
  };
};