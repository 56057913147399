import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["top", "left", "width", "height"];
import { css } from '@emotion/css';
import { px2rem, px2grid } from "../../utils";
export const main = () => css`
  backdrop-filter: blur(3px);
  background-image: linear-gradient(
    82.37deg,
    rgba(59, 219, 184, 0.8) 0%,
    rgba(1, 182, 208, 0.8) 100%
  );
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding-top: ${px2grid(64)};
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;
export const cloneWrap = _ref => {
  let {
    top = 0,
    left = 0,
    width = 0,
    height = 0
  } = _ref;
  return css`
  height: ${px2rem(height)} !important;
  left: ${px2rem(left)} !important;
  position: absolute !important;
  top: ${px2rem(top)} !important;
  width: ${px2rem(width)} !important;
`;
};
export const clone = _ref2 => {
  let {
    top,
    left,
    width,
    height
  } = _ref2,
      styles = _objectWithoutProperties(_ref2, _excluded);

  return css(styles);
};
export const closeButton = () => css`
  position: absolute;
  top: ${px2grid(64)};
  right: ${px2grid(16)};
  padding: ${px2grid(16)} !important;
`;