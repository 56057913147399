import { useVerifyUserPhoneNumber as useVerifyUserPhone } from '@ampli/services';

const useVerifyUserPhoneNumber = _ref => {
  let {
    settings = {},
    onSubmit,
    onError,
    onCompleted
  } = _ref;
  const grecaptcha = window.grecaptcha;
  const [mutate, metadata] = useVerifyUserPhone({
    onError,
    onCompleted
  });

  const handleSubmit = (values, captchaToken) => {
    const userId = values.userId;
    mutate({
      variables: {
        userId,
        captcha: {
          origin: 'web',
          token: captchaToken
        }
      }
    });
    onSubmit === null || onSubmit === void 0 || onSubmit(values);
  };

  const handleVerifyPhoneNumber = values => {
    if (!grecaptcha) {
      throw new Error("Google reCaptcha doesn't exist.");
    }

    grecaptcha.ready(() => {
      grecaptcha.execute(settings.recaptchaSiteKey, {
        action: 'verifyUserPhoneNumber'
      }).then(token => handleSubmit(values, token)).catch(console.error);
    });
  };

  return {
    handleVerifyPhoneNumber,
    metadata
  };
};

export default useVerifyUserPhoneNumber;