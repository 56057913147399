import React from 'react';
import { TextInput } from 'react-admin';
import { DocumentNumberInput } from '@ampli/ui';
import { object } from '@ampli/utils';

export const AdminCpfInput = (props) => (
  <TextInput
    label="CPF"
    source={props.source}
    InputProps={{ inputComponent: DocumentNumberInput, inputProps: {} }}
  />
);

AdminCpfInput.propTypes = {
  source: object,
};
