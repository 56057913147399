import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { Filter } from '../../components';

export const PartnerList = (props) => (
  <List
    {...props}
    filters={<Filter />}
    sort={{ field: 'name', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="name" label="Disciplina" />
    </Datagrid>
  </List>
);
