import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SelectInput,
  ShowButton,
  useUpdate,
} from 'react-admin';
import { DISPLAY_IN_VALUES } from './common';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { object, bool } from 'prop-types';

const styles = makeStyles({
  iconActionButton: {
    border: 'none',
    background: 'none',
    padding: 0,
  },
});

const ProductOfferDisplayInFilter = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        alwaysOn
        source="displayedIn"
        allowEmpty={false}
        choices={DISPLAY_IN_VALUES}
        label="Exibir em"
      ></SelectInput>
    </Filter>
  );
};

const ChangePositionButton = ({ record, increasePosition }) => {
  const classes = styles();
  const [update, { isProductOfferLoading, data: productOfferUpdateData }] =
    useUpdate(
      'productOffer',
      {
        id: record.id,
      },
      {
        ...record,
        sequence: increasePosition ? record.sequence - 1 : record.sequence + 1,
      },
      {
        record,
      }
    );

  const onChangeItemPosition = () => {
    update();
  };
  useEffect(() => {
    if (!isProductOfferLoading && productOfferUpdateData)
      window.location.reload();
  }, [productOfferUpdateData, isProductOfferLoading]);

  return (
    <button
      className={classes.iconActionButton}
      disabled={isProductOfferLoading}
      onClick={onChangeItemPosition}
    >
      {increasePosition ? <ArrowUpward /> : <ArrowDownward />}
    </button>
  );
};

ChangePositionButton.propTypes = {
  record: object,
  increasePosition: bool,
};

export const ProductOfferList = (props) => (
  <List
    {...props}
    filters={<ProductOfferDisplayInFilter />}
    filterDefaultValues={{ displayedIn: 'MY_COURSES' }}
    title="Novidades - List"
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField label="Título" source="name" />
      <TextField label="Texto 1" source="firstDescription" />
      <TextField label="Texto 2" source="secondDescription" />
      <TextField label="Texto 3" source="thirdDescription" />
      <TextField label="CTA" source="cta" />
      <TextField label="Link" source="urlWebPage" />
      <TextField label="Exibir em" source="displayedInTranslated" />
      <ChangePositionButton increasePosition={true} />
      <ChangePositionButton increasePosition={false} />
      <ShowButton />
    </Datagrid>
  </List>
);
