import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
import { Filter } from '../../components';

export const CandidateList = (props) => (
  <List
    title="Candidatos"
    {...props}
    sort={{ field: 'audit.lastModifiedDate', order: 'ASC' }}
    filters={<Filter />}
    empty={false}
  >
    <Datagrid rowClick="show" isRowSelectable={() => false}>
      <TextField label="Nome" source="firstName" />
      <TextField label="Celular" source="mobilePhone" />
      <TextField label="Email" source="email" />
      <TextField label="CPF" source="document" />
      <TextField label="Status" source="status" />
      <TextField label="Cidade" source="leadCity.name" sortable={false} />
      <TextField
        label="Estado"
        source="leadCity.state.acronym"
        sortable={false}
      />
      <ReferenceField
        label="Curso"
        source="courseId"
        reference="course"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField
        label="Método de Pagamento"
        source="paymentInfo.paymentMethod"
      />
      <TextField label="Dias parados" source="daysSinceCreated" />
    </Datagrid>
  </List>
);
