import gql from 'graphql-tag';
import { getOne, updateOne } from '../utils/resources';
const UPDATE_QUERY = gql`
  mutation UpdateUserPassword(
    $id: ID!
    $oldPassword: String!
    $newPassword: String!
  ) {
    data: updateUserPassword(
      id: $id
      oldPassword: $oldPassword
      newPassword: $newPassword
    )
  }
`;
const GET_ONE_QUERY = gql`
  query Me {
    data: me {
      id
    }
  }
`;
export default {
  updatePassword: updateOne(UPDATE_QUERY),
  getUserId: getOne(GET_ONE_QUERY)
};