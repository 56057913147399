import React from 'react';
import { List, Datagrid, TextField, BooleanField } from 'react-admin';

export const CourseCategoryList = (props) => (
  <List title="Categorias de Curso" {...props} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField label="Nome da Categoria" source="name" />
      <BooleanField label="Exibir" source="visible" />
      <BooleanField label="Categoria Principal" source="main" />
      <TextField label="Tipo de curso" source="formattedCourseDegree" />
    </Datagrid>
  </List>
);
