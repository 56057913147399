import { GET_LIST, GET_MANY, GET_ONE } from 'react-admin';
import gql from 'graphql-tag';
import { getList, getMany, getOne } from '@ampli/admin-core';

const ENTITY = `
  id
  name
`;

const LIST_QUERY = gql`
  query listAllCourseCategories($pagination: Pagination, $filter: Filter, $sort: Sort) {
    connection: listAllCourseCategories(pagination: $pagination, filter: $filter, sort: $sort) {
      data {
        ${ENTITY}
      }
    }
  }
`;

const GET_MANY_QUERY = gql`
  query getManyCourseCategoryByIds($ids: [String]!) {
    data: getManyCourseCategoryByIds (
      categoryIds: $ids
    ) {
      ${ENTITY}
    }
  }
`;

const GET_ONE_QUERY = gql`
  query getCourseCategory($id: ID!) {
    data: getCourseCategory (id: $id) {
      ${ENTITY}
    }
  }
`;

export default {
  [GET_LIST]: getList(LIST_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
  [GET_ONE]: getOne(GET_ONE_QUERY),
};
