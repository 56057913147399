import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ApolloClient, ApolloLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';
import { APIError, authStorage, compact, concat, forEach, GraphQLError, omitDeep } from '@ampli/utils';
import typePolicies from './type-policies';
import { createCss, showError } from './error/default-error-notifier';
export const logout = () => authStorage.clear();

const checkUnauthorizedStatus = status => status === 401 || status === 403;

const cacheCreator = function () {
  let cacheSettings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new InMemoryCache(_objectSpread({
    typePolicies
  }, cacheSettings)).restore(window.__APOLLO_STATE__ || {});
};

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, ['__typename']);
  }

  return forward(operation);
});
export const errorLink = onError(_ref => {
  let {
    graphQLErrors,
    operation,
    networkError
  } = _ref;
  const context = operation.getContext();
  const requestHeaders = context.headers || {};
  const response = context.response || {};
  const responseStatus = response.status;
  const operationName = operation.operationName;
  const autoHandle = context.autoHandle;

  const logoutCallback = () => {
    window.history.replaceState(null, '', '/');
  };

  const dispatchError = (message, status, ErrorClass) => {
    if (checkUnauthorizedStatus(status) && !context.skipLogout) {
      var _requestHeaders$autho;

      if (((_requestHeaders$autho = requestHeaders.authorization) === null || _requestHeaders$autho === void 0 ? void 0 : _requestHeaders$autho.indexOf('Bearer')) >= 0) {
        logout().then(logoutCallback).catch(console.error);
      }
    } else {
      console.error(new ErrorClass(message, {
        requestHeaders,
        responseStatus,
        operationName
      }));
    }
  };

  if (graphQLErrors) {
    forEach(graphQLErrors, error => {
      if (error.code === 'FORBIDDEN') {
        showError('Você não tem permissão para realizar esta ação');
      }

      if (autoHandle) {
        if (error.status === 406) {
          showError(error.message);
        } else if (error.status === 403) {
          showError('Você não tem permissão para realizar esta ação');
        } else {
          console.error(new GraphQLError(error.message, {
            requestHeaders,
            responseStatus,
            operationName
          }));
          showError('Tivemos um erro inesperado, por favor tente novamente.');
        }
      } else {
        dispatchError(`${error.code}: ${error.message} (${error.status})`, error.status, GraphQLError);
      }
    });
  }

  if (networkError) {
    dispatchError(networkError.message, responseStatus, APIError);
  }
});
const headersLink = setContext(_ref2 => {
  let {
    headers = {}
  } = _ref2;
  return {
    headers: _objectSpread({
      authorization: 'Basic cG9ydGFsOnNlY3JldA=='
    }, headers)
  };
});
const authLink = setContext(_ref3 => {
  let {
    headers = {}
  } = _ref3;
  return authStorage.getItem('token').then(token => token ? {
    headers: _objectSpread(_objectSpread({}, headers), {}, {
      authorization: `Bearer ${token}`
    })
  } : {
    headers
  }).catch(error => {
    console.error(error);
    return {
      headers
    };
  });
});
export const clientCreator = _ref4 => {
  let {
    graphqlUrl,
    links = [],
    secure = true,
    cacheSettings = {}
  } = _ref4;
  createCss();
  const link = from(compact(concat(links, [errorLink, headersLink, secure && authLink, omitTypenameLink, createUploadLink({
    uri: graphqlUrl
  })])));
  return new ApolloClient({
    cache: cacheCreator(cacheSettings),
    link,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all'
      },
      query: {
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  });
};