import { GET_LIST, GET_ONE, GET_MANY } from 'react-admin';
import gql from 'graphql-tag';
import { getList, getMany, getOne } from '@ampli/admin-core';
import { identity } from '@ampli/utils';

const ENTITY = `
  id
  name
  courseType {
    name
  }
  courseStructureDocument
`;

const parseGetList = (params) => {
  return {
    ...params,
    filter: {
      name: params.filter.q,
    },
  };
};

const LIST_QUERY = gql`
  query ListCourses($pagination: Pagination, $filter: JSON) {
    connection: listCourses(
        pagination: $pagination,
        filter: $filter,
        sort: { field: "name", order: ASC}
    ) {
      data {
        ${ENTITY}
      }
      page {
        totalElements
      }
    }
  }
`;

const GET_ONE_QUERY = gql`
  query GetCourse($id: ID!) {
    data: getCourse(courseId: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetCourses($ids: [ID!]!) {
    data: getCourses(
      courseIds: $ids
    ) {
      ${ENTITY}
    }
  }
`;

export default {
  [GET_LIST]: getList(LIST_QUERY, identity, parseGetList),
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
};
