import React from 'react';
import {
  ReferenceField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
    maxHeight: '100%',
  },
}));

export const PartnerShow = (props) => {
  const classes = useStyles();

  return (
    <Show title="Parceiro" {...props}>
      <SimpleShowLayout>
        <TextField label="ID do Parceiro" source="id" />
        <TextField label="Nome do Parceiro" source="name" />
        <TextField label="Descrição" source="description" />
        <ReferenceField label="Logo" source="logoId" reference="image">
          <ImageField source="file.src" classes={{ image: classes.image }} />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};
