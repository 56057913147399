import { DateTime } from '@ampli/utils';
import {
  choices,
  maxLength,
  minLength,
  minValue,
  number,
  required,
} from 'react-admin';

export const data = {
  types: [
    {
      id: 'PERCENTAGE',
      name: 'Percentual',
    },
    {
      id: 'EXACT',
      name: 'Exato',
    },
  ],
  categories: [
    {
      id: 'B2B',
      name: 'B2B',
    },
    {
      id: 'FREE_TRIAL',
      name: 'Free Trial',
    },
    {
      id: 'B2B_CONVENIO_KROTON',
      name: 'B2B Convênio Kroton',
    },
    {
      id: '',
      name: '',
    },
  ],
  courseDegree: [
    {
      id: 'GRADUATE',
      name: 'Pós Graduação',
    },
    {
      id: 'UNDERGRADUATE',
      name: 'Graduação',
    },
    {
      id: 'OPEN',
      name: 'Curso Livre',
    },
    {
      id: 'CORPORATE',
      name: 'Corporativo',
    },
    {
      id: 'EJA',
      name: 'EJA',
    },
    {
      id: 'TECHNICAL',
      name: 'Técnico',
    },
  ],
};

const validationDateShouldBeTodayOrAfter = (date) => {
  const formatDate = typeof date === 'string' ? DateTime.fromISO(date) : date;

  if (DateTime.local().startOf('hour') > formatDate.startOf('hour')) {
    return 'Data deve ser hoje ou mais a frente';
  }
};

const validationDateShouldBeAfter = (date) => {
  const formatDate = typeof date === 'string' ? DateTime.fromISO(date) : date;

  if (DateTime.local().startOf('hour') >= formatDate.startOf('hour')) {
    return 'Data deve ser mais a frente';
  }
};

export const validation = {
  code: [required(), minLength(3), maxLength(20)],
  description: [required(), minLength(1), maxLength(100)],
  discount: [required(), number(), minValue(1)],
  initialAmount: [required(), number(), minValue(1)],
  type: [required(), choices(data.types.map((m) => m.id))],
  startDate: [required(), validationDateShouldBeTodayOrAfter],
  expirationDate: [required(), validationDateShouldBeAfter],
  numberOfInstalments: [required(), minValue(1)],
};

export const format = {
  discount: (record) => {
    return record.type === 'PERCENTAGE'
      ? `${record.discount}%`
      : `R$ ${record.discount}`;
  },
};
