import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useQuery, useMutation } from '../hooks';
import { GET_VALID_COUPON, APPLY_COUPON } from './queries';
import { applyCouponSchema } from './schema';
export const useGetValidCoupon = function (couponCode, courseId, studentId) {
  let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const {
    loading,
    data,
    error
  } = useQuery(GET_VALID_COUPON, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      couponCode,
      courseId,
      studentId
    },
    skip: !couponCode
  }));
  const coupon = loading || !data ? null : data.data;
  return {
    coupon,
    loading,
    error
  };
};
export const useApplyCoupon = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let ignoreUseffectError = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const [mutate, metadata] = useMutation(APPLY_COUPON, _objectSpread({}, options), ignoreUseffectError);
  return [mutate, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: applyCouponSchema
  })];
};