import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { validation } from './common';

export const SellerCategoryCreate = (props) => (
  <Create title="Categoria dos Vendedores - Inserir" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nome" source="name" validate={validation.name} />
      <TextInput
        label="UTM Source"
        source="utmSource"
        validate={validation.utmSource}
      />
      <TextInput
        label="UTM Medium"
        source="utmMedium"
        validate={validation.utmMedium}
      />
      <TextInput
        label="UTM Campaign"
        source="utmCampaign"
        validate={validation.utmCampaign}
      />
      <TextInput
        label="Prefixo do Cupom"
        source="couponPrefix"
        validate={validation.couponPrefix}
      />
    </SimpleForm>
  </Create>
);
