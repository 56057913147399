import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ApolloClient, from, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import { createUploadLink } from 'apollo-upload-client';
import { createCss } from '@ampli/services';
import { authStorage, compact, concat, map, GraphQLError, APIError, omitDeep } from '@ampli/utils';

const cacheCreator = function () {
  let cacheSettings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new InMemoryCache(_objectSpread({}, cacheSettings));
};

const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, ['__typename']);
  }

  return forward(operation);
});
const errorLink = onError(_ref => {
  let {
    graphQLErrors,
    operation,
    networkError
  } = _ref;
  const context = operation.getContext();
  const response = context.response || {};

  if (graphQLErrors) {
    map(graphQLErrors, error => {
      console.error(new GraphQLError(`${error.code}: ${error.message} (${error.status})`, {
        requestHeaders: context.headers,
        responseStatus: response.status,
        operationName: operation.operationName
      }));
    });
  }

  if (networkError) {
    console.error(new APIError(networkError.message, {
      requestHeaders: context.headers,
      responseStatus: response.status,
      operationName: operation.operationName
    }));
  }
});
const authLink = setContext(_ref2 => {
  let {
    headers = {}
  } = _ref2;
  return authStorage.getItem('token').then(token => ({
    headers: _objectSpread({
      authorization: token ? `Bearer ${token}` : 'Basic cG9ydGFsOnNlY3JldA=='
    }, headers)
  }));
});
export const clientCreator = _ref3 => {
  let {
    graphqlUrl,
    links = [],
    secure = true,
    cacheSettings = {}
  } = _ref3;
  createCss();
  const link = from(compact(concat(links, [errorLink, secure && authLink, omitTypenameLink, createUploadLink({
    uri: graphqlUrl
  })])));
  return new ApolloClient({
    cache: cacheCreator(cacheSettings),
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  });
};