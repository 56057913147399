import gql from 'graphql-tag';
import { CREATE, DELETE, GET_LIST, GET_ONE, UPDATE } from 'react-admin';
import { deleteOne, getList, getOne } from '@ampli/admin-core';
import { omit } from '@ampli/utils';

const ENTITY = `
    id
    name
    iconLink
    main
    visible
    courseDegree
`;

const LIST_QUERY = gql`
    query ListCoursesCategories($pagination: Pagination, $filter: Filter, $sort: Sort) {
        connection: listAllCourseCategories(pagination: $pagination, filter: $filter, sort: $sort) {
            data {
                ${ENTITY}
            }
            page {
                number
                size
                totalElements
                totalPages
            }
        }
    }
`;

const GET_ONE_QUERY = gql`
    query GetCourseCategory($id: ID!) {
        data: getCourseCategory(id: $id) {
            ${ENTITY}
        }
    }
`;

const translatedWords = {
  UNDERGRADUATE: 'Graduação',
  GRADUATE: 'Pós Graduação',
  EJA: 'Eja',
  TECHNICAL: 'Técnico',
};

const CREATE_ONE_QUERY = gql`
    mutation CreateCourseCategory($data: CourseCategoryCreateInput!) {
        data: createCourseCategory(courseCategory: $data){
            ${ENTITY}
        }
    }
`;

const DELETE_ONE_QUERY = gql`
  mutation DeleteCourseCategory($id: ID!) {
    data: deleteCourseCategory(id: $id)
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateCourseCategory($data: CourseCategoryUpdateInput!) {
    data: updateCourseCategory(courseCategory: $data) {
      ${ENTITY}
    }
  }
`;

const parseResponse = (data) => {
  const formattedCourseDegree = translatedWords[data.courseDegree];
  return {
    formattedCourseDegree,
    ...data,
  };
};
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = reject;
  });

const parseParams = async ({ data }) => {
  data.visible = data.visible || false;
  data.main = data.main || false;
  if (data.icon) {
    data.icon = {
      name: data.icon.title.replace('.svg', ''),
      data: await toBase64(data.icon.rawFile),
    };
  }
  return {
    data: {
      ...omit(data, ['formattedCourseDegree']),
    },
  };
};

const createCategory = async ({ client, params }) => {
  const options = {
    query: CREATE_ONE_QUERY,
    variables: await parseParams(params),
  };

  return client.query(options).then(({ data }) => data);
};

const updateCategory = async ({ client, params }) => {
  const options = {
    query: UPDATE_ONE_QUERY,
    variables: await parseParams(params),
  };

  return client.query(options).then(({ data }) => data);
};

export default {
  [CREATE]: createCategory,
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
  [GET_LIST]: getList(LIST_QUERY, parseResponse),
  [GET_ONE]: getOne(GET_ONE_QUERY, parseResponse),
  [UPDATE]: updateCategory,
};
