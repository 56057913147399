import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_ONE,
  UPDATE,
  GET_MANY,
} from 'react-admin';
import {
  createOne,
  deleteOne,
  getList,
  getOne,
  updateOne,
  getMany,
} from '@ampli/admin-core';
import gql from 'graphql-tag';
import { omit } from '@ampli/utils';

const ENTITY = `
  id
  name
  categoryId
  categoryName
  visible
`;

const CREATE_ONE_QUERY = gql`
  mutation createCategorySection($data: CreateSectionInput!) {
    data: createCategorySection(categorySection: $data) {
      ${ENTITY}
    }
  }
`;

const DELETE_ONE_QUERY = gql`
  mutation deleteCategorySection($id: ID!) {
    data: deleteCategorySection(id: $id)
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation updateCategorySection($data: UpdateSectionInput!) {
    data: updateCategorySection(categorySection: $data) {
      ${ENTITY}
    }
  }
`;

const GET_ONE_QUERY = gql`
  query getCategorySection($id: ID!) {
    data: getCategorySection(id: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetCategorySections($ids: [ID!]!) {
    data: getCategorySections(
      ids: $ids
    ) {
      ${ENTITY}
    }
  }
`;

const LIST_QUERY = gql`
  query listCategorySections($pagination: Pagination, $filter: Filter, $sort: Sort) {
    connection: listCategorySections(pagination: $pagination, filter: $filter, sort: $sort) {
      data {
        ${ENTITY}
        categoryName
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

const parseResponse = (data) => {
  return {
    sectionLabelName: data.name + ' - ' + data.categoryName,
    ...data,
  };
};

const updateParseParams = ({ data }) => {
  return {
    data: {
      ...omit(data, ['section, sectionLabelName']),
    },
  };
};

export default {
  [CREATE]: createOne(CREATE_ONE_QUERY, parseResponse),
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
  [GET_LIST]: getList(LIST_QUERY, parseResponse),
  [GET_MANY]: getMany(GET_MANY_QUERY, parseResponse),
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [UPDATE]: updateOne(UPDATE_ONE_QUERY, parseResponse, updateParseParams),
};
