import { identity } from '@ampli/utils';

const parseResponse = function (response) {
  let settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  console.debug(settings.queryId, 'parseResponse', {
    response
  });
  const hasErrosOnRequest = response.errors && response.errors.length > 0;

  if (hasErrosOnRequest) {
    throw response.errors.map(m => m.message).join(' / ');
  }

  return Promise.resolve(response || {}).then(_ref => {
    let {
      data
    } = _ref;
    return data || {};
  });
};

const parseResponseDataStrategy = (parseDataObject, settings) => response => parseResponse(response, settings).then(async _ref2 => {
  let {
    data
  } = _ref2;
  return {
    data: await parseDataObject(data || {})
  };
});

const parseResponseDataListStrategy = (parseDataObject, settings) => response => parseResponse(response, settings).then(async _ref3 => {
  let {
    data
  } = _ref3;
  return {
    data: await Promise.all((data || []).map(parseDataObject))
  };
});

const parseResponseDataConnectionStrategy = (parseDataObject, settings) => response => parseResponse(response, settings).then(_ref4 => {
  let {
    connection
  } = _ref4;
  return connection !== null && connection !== void 0 && connection.data ? connection || {} : {
    data: connection || []
  };
}).then(async _ref5 => {
  let {
    data,
    page
  } = _ref5;
  return {
    data: await Promise.all((data || []).map(parseDataObject)),
    total: parseInt((page === null || page === void 0 ? void 0 : page.totalElements) || (data === null || data === void 0 ? void 0 : data.length) || 0, 10)
  };
});

const resourceCreator = parseResponseStrategy => function (query) {
  let parseResponseDataObject = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : identity;
  let parseRequestParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : identity;
  let autoHandle = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return _ref6 => {
    let {
      client,
      params,
      queryId
    } = _ref6;
    return client.query({
      query,
      variables: parseRequestParams(params),
      context: {
        autoHandle: autoHandle
      }
    }).then(parseResponseStrategy(parseResponseDataObject, {
      queryId
    }));
  };
};

export const getList = resourceCreator(parseResponseDataConnectionStrategy);
export const getOne = resourceCreator(parseResponseDataStrategy);
export const getMany = resourceCreator(parseResponseDataListStrategy);
export const getManyReference = resourceCreator(parseResponseDataConnectionStrategy);
export const updateOne = resourceCreator(parseResponseDataStrategy);
export const createOne = resourceCreator(parseResponseDataStrategy);
export const deleteOne = resourceCreator(parseResponseDataStrategy);