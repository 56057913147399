import gql from 'graphql-tag';
import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin';
import { deleteOne, getList, getMany, getOne } from '@ampli/admin-core';
import { omit } from '@ampli/utils';

const ENTITY = `
  id
  courseId
  titleTag
  courseCardImageUrl
  course {
    id
    name
  }
`;

const LIST_QUERY = gql`
  query ListCoursesSeo($pagination: Pagination, $filter: Filter, $sort: Sort) {
    connection: listCoursesSeo(pagination: $pagination, filter: $filter, sort: $sort) {
      data {
        ${ENTITY}
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

const GET_ONE_QUERY = gql`
  query GetCourseSeo($id: ID!) {
    data: getCourseSeo(id: $id) {
      ${ENTITY}
      sectionsIds
      sections {
        id
        categoryName
        aggregatedName
      }
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetCourseSeo {
    data: getCourseSeo {
      ${ENTITY}
    }
  }
`;

const CREATE_ONE_QUERY = gql`
  mutation CreateCourseSeo($data: CourseSeoCreateInput!) {
    data: createCourseSeo(courseSeo: $data) {
      ${ENTITY}
    }
  }
`;

const DELETE_ONE_QUERY = gql`
  mutation DeleteCourseSeo($id: ID!) {
    data: deleteCourseSeo(id: $id)
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateCourseSeo($data: CourseSeoUpdateInput!) {
    data: updateCourseSeo(courseSeo: $data) {
      ${ENTITY}
    }
  }
`;

const parseResponse = (data) => {
  return {
    ...data,
  };
};

const parseParams = async ({ data }) => {
  if (data.icon) {
    data.icon = {
      name: data.icon.title.replace('.svg', ''),
      data: await toBase64(data.icon.rawFile),
    };
  }
  return {
    data: {
      ...omit(data, ['course', 'sections']),
    },
  };
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = reject;
  });

const createCourseSeo = async ({ client, params }) => {
  const options = {
    query: CREATE_ONE_QUERY,
    variables: await parseParams(params),
  };

  return client.query(options).then(({ data }) => parseResponse(data));
};

const updateCourseSeo = async ({ client, params }) => {
  const options = {
    query: UPDATE_ONE_QUERY,
    variables: await parseParams(params),
  };

  return client.query(options).then(({ data }) => parseResponse(data));
};

export default {
  [CREATE]: createCourseSeo,
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
  [GET_LIST]: getList(LIST_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
  [GET_ONE]: getOne(GET_ONE_QUERY, parseResponse),
  [UPDATE]: updateCourseSeo,
};
