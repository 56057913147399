import React, { useState } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  FileInput,
  FileField,
} from 'react-admin';

export const LegalDocumentEdit = (props) => {
  const [newDocumentFile, setNewDocumentFile] = useState('');
  const [newUrl, setNewUrl] = useState('url');

  const onDocumentChange = (event) => {
    setNewDocumentFile(event);
    setNewUrl(' ');
  };

  return (
    <Edit title="Documentos Legais - Edit" undoable={false} {...props}>
      <SimpleForm redirect="list">
        <TextInput label="Nome" source="name" />
        <FileInput
          label="Substituir arquivo"
          name="file"
          accept=".pdf"
          source="file"
          multiple={false}
          onChange={onDocumentChange}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput
          label="Link"
          source={newUrl}
          disabled={newDocumentFile !== ''}
        />
        <BooleanInput label="Exibir" source="isEnabled" />
      </SimpleForm>
    </Edit>
  );
};
