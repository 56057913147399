import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  ExportButton,
} from 'react-admin';
import { string, object, bool, number } from '@ampli/utils';
import { Filter } from '../../components';

export const LeadList = (props) => (
  <List
    title="Leads"
    actions={<ListActions maxResults={1000} />}
    {...props}
    filters={<Filter />}
    empty={false}
  >
    <Datagrid rowClick="show">
      <TextField label="Email" source="email" />
      <TextField label="Celular" source="phone" />
    </Datagrid>
  </List>
);

const ListActions = ({
  currentSort,
  className,
  resource,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  maxResults,
  total,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{ ...filterValues, ...permanentFilter }}
      exporter={exporter}
      maxResults={maxResults}
    />
  </TopToolbar>
);

ListActions.propTypes = {
  currentSort: object,
  className: string,
  resource: object,
  exporter: bool, // you can hide ExportButton if exporter = (null || false)
  filterValues: object,
  permanentFilter: object,
  maxResults: number,
  total: number,
};
