import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

export const SellerRegionalEdit = (props) => (
  <Edit title="Regional de Vendedores - Atualizar" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nome" source="name" />
      <TextInput label="Descrição" source="description" />
    </SimpleForm>
  </Edit>
);
