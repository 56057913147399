import gql from 'graphql-tag';
import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin';
import {
  createOne,
  deleteOne,
  getList,
  getMany,
  getOne,
  updateOne,
} from '@ampli/admin-core';

const ENTITY = `
  id
  name
  utmSource
  utmCampaign
  utmMedium
  couponPrefix
`;

const LIST_QUERY = gql`
  query ListSellerCategory($pagination: Pagination, $filter: Filter, $sort: Sort) {
    connection: listSellerCategory(pagination: $pagination, filter: $filter, sort: $sort) {
      data {
        ${ENTITY}
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

const GET_ONE_QUERY = gql`
  query GetSellerCategory($id: ID) {
    data: getSellerCategory(id: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetManySellerCategories($ids: [ID!]!) {
    data: getManySellerCategories (
      ids: $ids
    ) {
      ${ENTITY}
    }
  }
`;

const CREATE_ONE_QUERY = gql`
  mutation CreateSellerCategory($data: SellerCategoryData) {
    data: createSellerCategory(category: $data) {
      ${ENTITY}
    }
  }
`;

const createOneParseObject = (result) => result;

const createOneParseParams = (params) => {
  return params;
};

const DELETE_ONE_QUERY = gql`
  mutation DeleteSellerCategory($id: ID!) {
    data: deleteSellerCategory(id: $id)
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateSellerCategory($id: ID, $data: SellerCategoryData) {
    data: updateSellerCategory(id: $id, category: $data) {
      ${ENTITY}
    }
  }
`;

const updateParseObject = (result) => result;

const updateParseParams = (params) => {
  delete params.data.id;

  return params;
};

export default {
  [CREATE]: createOne(
    CREATE_ONE_QUERY,
    createOneParseObject,
    createOneParseParams
  ),
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
  [GET_LIST]: getList(LIST_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [UPDATE]: updateOne(UPDATE_ONE_QUERY, updateParseObject, updateParseParams),
};
