import { GET_LIST, GET_ONE, GET_MANY } from 'react-admin';
import gql from 'graphql-tag';
import { getList, getMany, getOne } from '@ampli/admin-core';

const ENTITY = `
  id
  firstInstalmentPrice
  otherInstalmentsPrice
`;

const LIST_QUERY = gql`
    query ListCoursePricesStub($pagination: Pagination, $filter: Filter, $sort: Sort) {
        connection: listCoursePricesStub {
          ${ENTITY}
        }
    }
`;

const GET_ONE_QUERY = gql`
  query GetCoursePriceStub($id: ID!) {
    data: getCoursePriceStub(coursePriceId: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetCoursePricesStub($ids: [ID!]!) {
    data: getCoursePricesStub (
      coursePriceIds: $ids
    ) {
      ${ENTITY}
    }
  }
`;

export default {
  [GET_LIST]: getList(LIST_QUERY),
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
};
