import React, { useState } from 'react';
import { Edit, SimpleForm, ReferenceInput } from 'react-admin';
import { SelectImageInput } from '@ampli/admin-core';

export const BannerEdit = (props) => {
  const [filter, setFilter] = useState({ tags: '' });
  const labelStyle = 'font-size: 22px; font-weight: bold';

  const onChangeFilterImage = (value) => {
    setFilter({ tags: value });
  };

  return (
    <Edit title="Editar banner da vitrine" undoable={false} {...props}>
      <SimpleForm redirect="list">
        <ReferenceInput
          label="Banner para home versão desktop (1920x726)"
          labelStyle={labelStyle}
          source="desktopImageId"
          reference="image"
          filter={filter}
          perPage={100}
        >
          <SelectImageInput
            imageFormName={'imageDesktop'}
            removeImageButton={true}
            onFilterChange={onChangeFilterImage}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Banner para home versão mobile (375x617)"
          labelStyle={labelStyle}
          source="mobileImageId"
          reference="image"
          filter={filter}
          perPage={100}
        >
          <SelectImageInput
            imageFormName={'imageMobile'}
            removeImageButton={true}
            onFilterChange={onChangeFilterImage}
          />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
