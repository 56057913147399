import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { ApolloClient, createHttpLink, from } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import fetch from 'cross-fetch';
import { onError } from '@apollo/client/link/error';
import { APIError, compact, GraphQLError, map } from '@ampli/utils';
import { setContext } from '@apollo/client/link/context';
const errorLink = onError(_ref => {
  let {
    graphQLErrors,
    operation,
    networkError
  } = _ref;
  const context = operation.getContext();
  const requestHeaders = context.headers || {};
  const response = context.response || {};
  const responseStatus = response.status;
  const operationName = operation.operationName;

  if (graphQLErrors) {
    map(graphQLErrors, error => {
      console.error(new GraphQLError(`${error.code}: ${error.message} (${error.status})`, {
        requestHeaders,
        responseStatus,
        operationName
      }));
    });
  }

  if (networkError) {
    console.error(new APIError(networkError.message, {
      requestHeaders,
      responseStatus,
      operationName
    }));
  }
});
const headersLink = setContext(_ref2 => {
  let {
    headers = {}
  } = _ref2;
  return {
    headers: _objectSpread({
      authorization: 'Basic cG9ydGFsOnNlY3JldA=='
    }, headers)
  };
});

const ssrClientCreator = _ref3 => {
  let {
    graphqlUrl
  } = _ref3;
  const link = from(compact([errorLink, headersLink, createHttpLink({
    uri: graphqlUrl,
    fetch
  })]));
  return new ApolloClient({
    ssrMode: true,
    link,
    cache: new InMemoryCache()
  });
};

export default ssrClientCreator;