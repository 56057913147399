import React from 'react';
import {
  Filter,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  TextInput,
} from 'react-admin';

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nome" source="q" alwaysOn />
  </Filter>
);

export const DefaultCoursePriceList = (props) => (
  <List
    title="Preço dos cursos"
    {...props}
    bulkActionButtons={false}
    filters={<PostFilter />}
  >
    <Datagrid rowClick="edit">
      <ReferenceField
        label="Nome do curso"
        source="courseId"
        link={false}
        reference="course"
        sort={{
          field: 'name',
          order: 'ASC',
        }}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Tipo do curso"
        source="courseId"
        link={false}
        reference="course"
      >
        <TextField fi source="courseType.name" />
      </ReferenceField>
      <TextField label="Primeira mensalidade" source="firstInstalmentPrice" />
      <TextField label="Demais mensalidades" source="otherInstalmentsPrice" />
    </Datagrid>
  </List>
);
