import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
const _excluded = ["data"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useMutation, useQuery, useLazyQuery } from '../hooks';
import { CANCEL_PENDING_INSTALMENT, CHANGE_BILLING_DAY, GET_AVAILABLE_BILLING_DAYS, GET_INSTALMENT_STATUS, PAY_INSTALMENT_BY_BOLETO, PAY_INSTALMENT_BY_EXISTING_CREDIT_CARD, PAY_INSTALMENT_BY_NEW_CREDIT_CARD, GET_MONTH_OF_NEW_BILLING_DAY, GENERATE_BOLETO_FILE, GET_INVOICE_DOCUMENT, GET_NEGOTIATION_URL } from './queries';
import { payInstalmentByNewCreditCardSchema } from './schemas';
export const usePayInstalmentByBoleto = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(PAY_INSTALMENT_BY_BOLETO, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    }
  }));
};
export const usePayInstalmentByNewCreditCard = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const mutation = useMutation(PAY_INSTALMENT_BY_NEW_CREDIT_CARD, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    }
  }));
  return [...mutation, payInstalmentByNewCreditCardSchema];
};
export const usePayInstalmentByExistingCreditCard = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(PAY_INSTALMENT_BY_EXISTING_CREDIT_CARD, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    }
  }));
};
export const useChangeBillingDay = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(CHANGE_BILLING_DAY, options);
};
export const useGetAvailableBillingDays = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useQuery(GET_AVAILABLE_BILLING_DAYS, options);
};
export const useGetInstalmentStatus = function (instalmentId, courseEnrollmentId) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  const _useQuery = useQuery(GET_INSTALMENT_STATUS, _objectSpread(_objectSpread({
    skip: !instalmentId
  }, options), {}, {
    variables: {
      instalmentId,
      courseEnrollmentId
    },
    fetchPolicy: 'no-cache'
  })),
        {
    data
  } = _useQuery,
        rest = _objectWithoutProperties(_useQuery, _excluded);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export const useCancelPendingInstalment = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(CANCEL_PENDING_INSTALMENT, options);
};
export const useGenerateBoletoFile = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(GENERATE_BOLETO_FILE, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    }
  }));
};
export const useGetMonthOfNewBillingDay = function (paymentPlanId, billingDay) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return useQuery(GET_MONTH_OF_NEW_BILLING_DAY, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      paymentPlanId,
      billingDay
    },
    context: {
      autoHandle: true
    }
  }));
};
export const useGetInvoiceDocument = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(GET_INVOICE_DOCUMENT, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const useGetNegotiationUrl = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useLazyQuery(GET_NEGOTIATION_URL, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId
    },
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};