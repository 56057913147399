import gql from 'graphql-tag';
import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin';
import {
  createOne,
  deleteOne,
  getList,
  getMany,
  getOne,
  updateOne,
} from '@ampli/admin-core';
import { omit } from '@ampli/utils';

const ENTITY = `
    id
    name
    description
    logoId
`;

const LIST_QUERY = gql`
    query PartnerList($pagination: Pagination, $filter: Filter, $sort: Sort) {
        connection: listPartner(pagination: $pagination, filter: $filter, sort: $sort) {
            data {
                ${ENTITY}
            }
            page {
                number
                size
                totalElements
                totalPages
            }
        }
    }
`;

const GET_ONE_QUERY = gql`
    query GetPartner($id: ID!) {
        data: getPartner(id: $id) {
            ${ENTITY}
        }
    }
`;

const GET_MANY_QUERY = gql`
    query GetManyPartners($ids: [ID!]!) {
        data: getManyPartners(ids: $ids) {
            ${ENTITY}
        }
    }
`;

const CREATE_ONE_QUERY = gql`
    mutation CreatePartner($data: PartnerInput!) {
        data: createPartner(partner: $data){
            ${ENTITY}
        }
    }
`;

const DELETE_ONE_QUERY = gql`
  mutation DeletePartner($id: ID!) {
    data: deletePartner(id: $id)
  }
`;

const UPDATE_ONE_QUERY = gql`
    mutation UpdatePartner($id: ID!, $partnerInput: PartnerInput!){
        data: updatePartner(id: $id, partner: $partnerInput){
            ${ENTITY}
        }
    }
`;

const updateParseParams = (data) => {
  const id = data.id;

  return {
    partnerInput: {
      ...omit(data.data, ['id', 'image', 'previousData']),
    },
    id,
  };
};

const parseRequestParams = ({ data }) => {
  return {
    data: {
      ...omit(data, ['image']),
    },
  };
};

const parseResponse = (data) => {
  return {
    ...data,
  };
};

export default {
  [CREATE]: createOne(CREATE_ONE_QUERY, parseResponse, parseRequestParams),
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
  [GET_LIST]: getList(LIST_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [UPDATE]: updateOne(UPDATE_ONE_QUERY, parseResponse, updateParseParams),
};
