import React from 'react';
import {
  DateInput,
  Edit,
  email,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import { AdminCpfInput, AdminPhoneInput } from '../../components';

const validateEmail = email();

export const SellerEdit = (props) => (
  <Edit title="Vendedor - Edit" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nome" source="name" />
      <TextInput
        label="Email"
        source="email"
        validate={validateEmail}
        type="email"
      />
      <DateInput label="Data de Nascimento" source="birthDate" />
      <AdminPhoneInput source="phone" />
      <AdminCpfInput source="cpf" />
      <ReferenceInput
        label="Unidade"
        source="sellerUnitId"
        reference="sellerUnit"
        allowEmpty
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Categoria"
        source="sellerCategoryId"
        reference="sellerCategory"
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput
        label="Cupons do Vendedor"
        source="sellerCoupons"
        reference="coupon"
        perPage={20}
      >
        <AutocompleteArrayInput source="code" optionText="code" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);
