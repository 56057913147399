import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import colors from "../utils/colors";
const graduateTheme = {
  colors: _objectSpread(_objectSpread({}, colors), {}, {
    'brand-base': '#1ac09c',
    'brand-light': '#3bdbb8',
    'brand-lighter': '#d8f8f1',
    'gradient-linear-brand': 'linear-gradient(233.53deg, #4dcddf -26.85%, #00b085 100%)',
    'gradient-linear-brand-light': 'linear-gradient(180deg, #d8f8f1 0%, #ffffff 100%)',
    'gradient-linear-brand-alpha': 'linear-gradient(82.37deg, rgba(59, 219, 184, 0.3) 0%, #00B085 100%)'
  })
};
export default graduateTheme;