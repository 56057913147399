import React from 'react';

import {
  AppLayout,
  authProviderCreator,
  clientCreator,
  CustomRoutes,
  dataProviderCreator,
  i18nProviderCreator,
  LoginPage,
  themeCreator,
} from '@ampli/admin-core';

import { createBrowserHistory as createHistory } from 'history';
import { ServiceProvider, errorLink } from '@ampli/services';
import { Admin, Resource } from 'react-admin';

import { ampli, graphqlUrl } from './config';

import possibleTypes from './fragment-types.json';
import ImageIcon from '@material-ui/icons/PhotoLibrary';

import * as resources from './resources';
import * as Views from './views';

const client = clientCreator({
  ...ampli,
  graphqlUrl,
  links: errorLink,
  cacheSettings: {
    possibleTypes,
  },
});

const authProvider = authProviderCreator({ client });
const dataProvider = dataProviderCreator({ client, resources });
const i18nProvider = i18nProviderCreator();
const theme = themeCreator();

export const App = () => {
  return (
    <ServiceProvider
      defaultClient="publicClient"
      clients={{ publicClient: client }}
    >
      <Admin
        theme={theme}
        history={createHistory()}
        loginPage={LoginPage}
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        customRoutes={CustomRoutes}
        layout={AppLayout}
      >
        <Resource
          name="affiliate"
          create={Views.AffiliateCreate}
          list={Views.AffiliateList}
          edit={Views.AffiliateEdit}
          options={{ label: 'Núcleos de Apoio' }}
        />
        <Resource
          name="lead"
          list={Views.LeadList}
          create={Views.LeadCreate}
          show={Views.LeadShow}
          edit={Views.LeadEdit}
          options={{ label: 'Leads' }}
        />
        <Resource
          name="candidate"
          list={Views.CandidateList}
          show={Views.CandidateShow}
          edit={Views.CandidateEdit}
          options={{ label: 'Candidatos' }}
        />
        <Resource
          name="sellerRegional"
          list={Views.SellerRegionalList}
          create={Views.SellerRegionalCreate}
          show={Views.SellerRegionalShow}
          edit={Views.SellerRegionalEdit}
          options={{ label: 'Regional dos Vendedores' }}
        />
        <Resource
          name="sellerUnit"
          list={Views.SellerUnitList}
          create={Views.SellerUnitCreate}
          show={Views.SellerUnitShow}
          edit={Views.SellerUnitEdit}
          options={{ label: 'Unidade dos Vendedores' }}
        />
        <Resource
          name="sellerCategory"
          list={Views.SellerCategoryList}
          create={Views.SellerCategoryCreate}
          show={Views.SellerCategoryShow}
          edit={Views.SellerCategoryEdit}
          options={{ label: 'Categoria dos Vendedores' }}
        />
        <Resource
          name="seller"
          list={Views.SellerList}
          create={Views.SellerCreate}
          show={Views.SellerShow}
          edit={Views.SellerEdit}
          options={{ label: 'Vendedores' }}
        />
        <Resource
          name="coupon"
          list={Views.CouponList}
          create={Views.CouponCreate}
          edit={Views.CouponEdit}
          options={{ label: 'Cupons' }}
        />
        <Resource
          name="defaultCoursePrice"
          list={Views.DefaultCoursePriceList}
          edit={Views.DefaultCoursePriceEdit}
          options={{ label: 'Preço dos cursos' }}
        />
        <Resource
          name="partner"
          list={Views.PartnerList}
          create={Views.PartnerCreate}
          show={Views.PartnerShow}
          edit={Views.PartnerEdit}
          options={{ label: 'Parceiros' }}
        />

        <Resource
          name="productOffer"
          list={Views.ProductOfferList}
          create={Views.ProductOfferCreate}
          show={Views.ProductOfferShow}
          edit={Views.ProductOfferEdit}
          options={{ label: 'Novidades' }}
        />

        <Resource
          name="legalDocument"
          list={Views.LegalDocumentList}
          create={Views.LegalDocumentCreate}
          show={Views.LegalDocumentShow}
          edit={Views.LegalDocumentEdit}
          options={{ label: 'Documentos Legais' }}
        />

        <Resource
          name="courseSEO"
          list={Views.CourseSEOList}
          create={Views.CourseSEOCreate}
          show={Views.CourseSEOShow}
          edit={Views.CourseSEOEdit}
          options={{ label: 'SEO de Cursos' }}
        />
        <Resource
          name="banner"
          list={Views.BannerList}
          edit={Views.BannerEdit}
          options={{ label: 'Banner' }}
          icon={ImageIcon}
        />
        <Resource
          name="section"
          list={Views.SectionList}
          edit={Views.SectionEdit}
          create={Views.SectionCreate}
          options={{ label: 'Seções de Cursos' }}
          show={Views.SectionShow}
        />
        <Resource
          name="courseCategory"
          list={Views.CourseCategoryList}
          create={Views.CourseCategoryCreate}
          show={Views.CourseCategoryShow}
          edit={Views.CourseCategoryEdit}
          options={{ label: 'Categoria de Curso' }}
        />

        {/* KEEP AT BOTTOM */}
        {/* Do not remove this resources cause they are being used as Reference Field */}
        <Resource name="course" />
        <Resource name="coursePrice" />
        <Resource name="city" />
        <Resource name="state" />
        <Resource name="image" />
        <Resource name="category" />
        <Resource name="sections" />
      </Admin>
    </ServiceProvider>
  );
};
