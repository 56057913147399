import React from 'react';
import ReactDOM from 'react-dom';
import { injectRootStyles } from '@ampli/admin-core';
import { initializeStorages } from '@ampli/utils';

import './lib/logger';
import { App } from './app';

injectRootStyles();
initializeStorages('admin');

ReactDOM.render(<App />, document.getElementById('root'));
