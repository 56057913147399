import { request } from '../request';
export async function getCurrentTime() {
  let APIURL = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : `https://www.ampli.com.br/time`;

  try {
    const date = await request(APIURL);
    if (!date) throw new Error('Could not fetch time');
    let returnedDate = new Date(date);
    const GMT_MINUS_THREE_OFFSET_IN_MILISECONDS = -(3 * 60 * 60 * 1000); // GMT-3 timezone offset

    const timeZoneOffset = returnedDate.getTimezoneOffset() !== 180 ? GMT_MINUS_THREE_OFFSET_IN_MILISECONDS : 0;
    returnedDate.setTime(returnedDate.getTime() + timeZoneOffset);
    return returnedDate;
  } catch (e) {
    console.error(e);
  }
}