import { hijackConsole } from '@ampli/logger';

import { name, isProduction, logLevel, sentryDsn, ampli } from '../config';

const sentrySettings = {
  dsn: sentryDsn,
  environment: ampli.env,
};

hijackConsole({
  appName: name,
  logLevel,
  debugMode: !isProduction,
  isNode: false,
  sentrySettings,
});
