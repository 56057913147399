import gql from 'graphql-tag';
import { DateTime, omit, sumBy } from '@ampli/utils';
import {
  deleteOne,
  getList,
  getMany,
  getOne,
  updateOne,
} from '@ampli/admin-core';

const parseObject = (data) => {
  const exam = data.exam || {};
  const today = DateTime.local();
  const daysSinceCreated = today.diff(
    DateTime.fromISO(data.audit.lastModifiedDate),
    ['days']
  ).days;
  return {
    ...data,
    firstName: data.fullName ? data.fullName.split(' ')[0] : '',
    daysSinceCreated: parseInt(daysSinceCreated),
    grade: sumBy(exam.examGrades || [], 'grade'),
    birthDateParsed: DateTime.fromISO(data.birthDate).toFormat('dd/MM/yyyy'),
  };
};

const updateParseParams = (params) => {
  return {
    data: {
      ...omit(params.data, [
        'leadCity',
        'audit',
        'firstName',
        'daysSinceCreated',
        'birthDateParsed',
        'exam',
        'paymentInfo',
        'grade',
      ]),
      address: params.data.address
        ? omit(params.data.address, ['city', 'state'])
        : null,
    },
  };
};

const parseParams = (params) => {
  const { sort } = params;
  if (sort && sort.field) {
    switch (sort.field) {
      case 'daysSinceCreated':
        params.sort.field = 'audit.createdDate';
        break;
      case 'firstName':
        params.sort.field = 'fullName';
        break;
      default:
        break;
    }
  }
  return params;
};

const ENTITY = `
  id
  fullName
  email
  mobilePhone
  coursePriceId
  courseId
  leadCityId
  leadCity {
    name
    state {
      id
      acronym
    }
  }
  address {
    street
    number
    stateId
    state {
        acronym
    }
    postalCode
    complement
    neighbourhood
    cityId
    city {
        name
    }
  }
  document
  birthDate
  enemOption
  enemGrade
  specialNeeds {
      description
  }
  status
  exam {
    examGrades {
      type
      grade
    }
  }
  nationalIdentity
  issuingAuthority
  nationalIdentityStateId
  gender
  maritalStatus
  countryOfBirth
  stateOfBirthId
  cityOfBirthId
  contractSigned
  contractId
  contractUrl
  paymentInfo {
    paymentMethod
  }
  sellerId
  audit {
    createdDate
    lastModifiedDate
  }
`;

const LIST_QUERY_WITH_STATUS = (status) => gql`
  query ListCandidates($pagination: Pagination, $sort: Sort) {
    connection: findCandidatesByStatus(
      pagination: $pagination
      status: ${status}
      sort: $sort
    ) {
      data {
        ${ENTITY}
      }
      page {
        totalElements
      }
    }
  }
`;

const LIST_QUERY = gql`
  query ListCandidates($filter: Filter, $pagination: Pagination, $sort: Sort) {
    connection: listCandidates(
      pagination: $pagination
      filter: $filter
      sort: $sort
    ) {
      data {
        ${ENTITY}
      }
      page {
        totalElements
      }
    }
  }
`;

const GET_ONE_QUERY = gql`
  query GetCandidate($id: ID!) {
    data: getCandidate(candidateId: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetCandidates($ids: [ID!]!) {
    data: getCandidates (
      candidateIds: $ids
    ) {
      ${ENTITY}
    }
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateCandidate($data: UpdateCandidateInput!) {
    data: updateCandidate(candidate: $data) {
      ${ENTITY}
    }
  }
`;

const DELETE_ONE_QUERY = gql`
  mutation DeleteCandidate($id: ID!) {
    data: deleteCandidate(candidateId: $id)
  }
`;
export default {
  candidateList: getList(LIST_QUERY, parseObject, parseParams),
  candidateListWithStatus: (status) =>
    getList(LIST_QUERY_WITH_STATUS(status), parseObject, parseParams),
  candidateGetOne: getOne(GET_ONE_QUERY, parseObject),
  candidateGetMany: getMany(GET_MANY_QUERY, parseObject),
  candidateUpdate: updateOne(UPDATE_ONE_QUERY, parseObject, updateParseParams),
  candidateDelete: deleteOne(DELETE_ONE_QUERY),
};
