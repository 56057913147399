import React from 'react';
import { Create, TabbedForm } from 'react-admin';

import PartnerFormTab from '../../components/partner-form-tab';

export const PartnerCreate = (props) => (
  <Create title="Novo Parceiro" {...props}>
    <TabbedForm>
      <PartnerFormTab />
    </TabbedForm>
  </Create>
);
