import { GET_LIST, GET_ONE, GET_MANY } from 'react-admin';
import gql from 'graphql-tag';
import { sortBy } from '@ampli/utils';

import { getMany, getOne } from '@ampli/admin-core';

const ENTITY = `
  id
  name
`;

const parseParams = (params) => {
  return {
    ...params,
    stateId: params.filter.stateId,
    filter: null,
  };
};

const LIST_QUERY = gql`
  query FindCities($stateId: Int!) {
    connection: findCities(
      stateId: $stateId
    ) {
      ${ENTITY}
    }
  }
`;

const getList = ({ client, params }) => {
  const options = {
    query: LIST_QUERY,
    variables: parseParams(params),
  };

  return client
    .query(options)
    .then(({ data }) => data.connection)
    .then((data) => {
      return {
        data: sortBy(data, 'name'),
        total: data.length,
      };
    });
};

const GET_ONE_QUERY = gql`
  query GetCity($id: Int!) {
    data: getCity(cityId: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetCities($ids: [Int!]!) {
    data: getCities (
      cityIds: $ids
    ) {
      ${ENTITY}
    }
  }
`;

export default {
  [GET_LIST]: getList,
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
};
