import { injectGlobal, ColorNeutralBackground } from '@ampli/ui';
import '@ampli/ui/dist/global-styles';
export const injectRootStyles = () => {
  injectGlobal`
    .grecaptcha-badge { visibility: hidden; }

    html,
    body {
      height: 100%;
    }

    body {
      background-color: ${ColorNeutralBackground};
    }

    #root, #root > div:not([class]) {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: auto;
      min-height: 100%;
    }
  `;
};