import { GET_LIST, GET_ONE, GET_MANY } from 'react-admin';
import gql from 'graphql-tag';
import { sortBy } from '@ampli/utils';

import { getMany, getOne } from '@ampli/admin-core';

const ENTITY = `
  id
  name
  acronym
`;

const LIST_QUERY = gql`
  query FindStates {
    connection: findStates {
      ${ENTITY}
    }
  }
`;

const getList = ({ client, params }) => {
  const options = {
    query: LIST_QUERY,
    variables: params,
  };

  return client
    .query(options)
    .then(({ data }) => data.connection)
    .then((data) => {
      return {
        data: sortBy(data, 'acronym'),
        total: data.length,
      };
    });
};

const GET_ONE_QUERY = gql`
  query GetState($id: Int!) {
    data: getState(stateId: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetStates($ids: [Int!]!) {
    data: getStates (
      stateIds: $ids
    ) {
      ${ENTITY}
    }
  }
`;

export default {
  [GET_LIST]: getList,
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
};
