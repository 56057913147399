import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ExportButton,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
} from 'react-admin';
import { bool, number, object, string } from '@ampli/utils';

export const SellerCategoryList = (props) => (
  <List
    title="Categoria de Vendedores - Lista"
    actions={<ListActions maxResults={5000} />}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField label="Nome" source="name" />
      <TextField label="UTM Source" source="utmSource" />
      <TextField label="UTM Campaign" source="utmCampaign" />
      <TextField label="UTM Medium" source="utmMedium" />
      <TextField label="Prefixo do Cupom" source="couponPrefix" />
    </Datagrid>
  </List>
);

const ListActions = ({
  currentSort,
  className,
  resource,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  basePath,
  maxResults,
  total,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{ ...filterValues, ...permanentFilter }}
      exporter={exporter}
      maxResults={maxResults}
    />
  </TopToolbar>
);

ListActions.propTypes = {
  currentSort: object,
  className: string,
  resource: object,
  exporter: bool, // you can hide ExportButton if exporter = (null || false)
  filterValues: object,
  permanentFilter: object,
  basePath: string,
  maxResults: number,
  total: number,
};
