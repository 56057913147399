import React from 'react';
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { validation } from './common';

export const SellerUnitCreate = (props) => (
  <Create title="Unidade dos Vendedores - Inserir" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Nome" source="name" validate={validation.name} />
      <TextInput
        label="Descrição"
        source="description"
        validate={validation.description}
      />
      <ReferenceInput
        label="Regional"
        source="sellerRegionalId"
        reference="sellerRegional"
      >
        <SelectInput optionText="name" validate={validation.regional} />
      </ReferenceInput>
      <TextInput
        label="Coordenador"
        source="managerName"
        validate={validation.managerName}
      />
    </SimpleForm>
  </Create>
);
