import React from 'react';
import {
  List,
  Datagrid,
  EmailField,
  ReferenceField,
  TextField,
  ExportButton,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import { Filter } from '../../components';
import { bool, number, object, string } from '@ampli/utils';

export const SellerList = (props) => (
  <List
    title="Vendedores"
    actions={<ListActions maxResults={5000} />}
    {...props}
    filters={<Filter />}
    empty={false}
  >
    <Datagrid rowClick="show">
      <TextField label="Nome" source="name" />
      <TextField label="CPF" source="cpf" />
      <EmailField source="email" />
      <TextField label="Data de Nascimento" source="birthDate" />
      <TextField label="Telefone" source="phone" />
      <ReferenceField
        label="Unidade"
        source="sellerUnitId"
        reference="sellerUnit"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Categoria"
        source="sellerCategoryId"
        reference="sellerCategory"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField
        label="Cupom"
        reference="coupon"
        source="sellerCoupons"
      >
        <SingleFieldList>
          <ChipField source="code" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);

const ListActions = ({
  currentSort,
  className,
  resource,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  basePath,
  maxResults,
  total,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{ ...filterValues, ...permanentFilter }}
      exporter={exporter}
      maxResults={maxResults}
    />
  </TopToolbar>
);

ListActions.propTypes = {
  currentSort: object,
  className: string,
  resource: object,
  exporter: bool, // you can hide ExportButton if exporter = (null || false)
  filterValues: object,
  permanentFilter: object,
  basePath: string,
  maxResults: number,
  total: number,
};
