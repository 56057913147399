import React, { useRef } from 'react';
import { useInput, FieldTitle, InputHelperText } from 'react-admin';
import { Editor } from '@tinymce/tinymce-react';
import {
  FormHelperText,
  FormControl,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import { tinyMceInit } from '@ampli/admin-core';
import { string, any, bool, object } from '@ampli/utils';

import { tinyMceApiKey } from '../config';

const useStyles = makeStyles({
  label: {
    position: 'relative',
    transform: 'none',
  },
});

const HtmlInput = (props) => {
  const classes = useStyles(props);
  const {
    helperText,
    label: labelText,
    source,
    resource,
    fullWidth,
    init,
    margin,

    ...rest
  } = props;
  const editorRef = useRef(null);

  let { disabled } = props;

  const {
    id,
    isRequired,
    input: { value, onChange },
    meta: { touched, error },
  } = useInput({ source, ...rest });

  disabled = disabled || false;

  return (
    <FormControl
      error={!!(touched && error)}
      fullWidth={fullWidth}
      margin={margin}
    >
      {labelText !== '' && labelText !== false && (
        <InputLabel htmlFor={id} className={classes.label}>
          <FieldTitle
            label={labelText}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
      )}
      <Editor
        id={id}
        apiKey={tinyMceApiKey}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onBlur={() => {
          if (editorRef.current) onChange(editorRef.current.getContent());
        }}
        initialValue={value}
        init={{
          ...tinyMceInit,
          ...init,
        }}
        disabled={disabled}
      />
      <FormHelperText
        error={!!error}
        className={error ? 'html-input-error' : ''}
      >
        <InputHelperText
          error={error}
          helperText={helperText}
          touched={touched}
        />
      </FormHelperText>
    </FormControl>
  );
};
HtmlInput.propTypes = {
  id: string,
  label: string,
  source: string,
  fullWidth: bool,
  resource: string,
  margin: string,
  helperText: any,
  init: object,
  disabled: bool,
};
HtmlInput.defaultProps = {
  init: {},
  margin: 'dense',
};

export default HtmlInput;
