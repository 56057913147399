import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

export const LeadShow = (props) => (
  <Show title="Lead" {...props}>
    <SimpleShowLayout>
      <TextField label="Email" source="email" />
      <TextField label="Celular" source="phone" />
    </SimpleShowLayout>
  </Show>
);
