import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { authStorage, useFormik } from '@ampli/utils';
import { useGetAmpliUserLoginInfo } from '@ampli/services';
import useVerifyUserPhoneNumber from './use-verify-phone-number';

const useGetAmpliUserLoginInfoForm = _ref => {
  var _window;

  let {
    settings = {},
    schemaOptions = {},
    onSubmit,
    onError,
    onCompleted
  } = _ref;
  const grecaptcha = (_window = window) === null || _window === void 0 ? void 0 : _window.grecaptcha;
  const {
    handleVerifyPhoneNumber,
    metadata: verifyPhoneNumberMetadata
  } = useVerifyUserPhoneNumber({
    settings,
    onSubmit,
    onError,
    onCompleted
  });
  const [getAmpliUserLoginInfo, getAmpliUserLoginInfoMetadata] = useGetAmpliUserLoginInfo({
    onError,
    onCompleted: _ref2 => {
      let {
        data
      } = _ref2;
      authStorage.setItem('loginInfo', data).catch(console.error);
      handleVerifyPhoneNumber(data);
    }
  });

  const handleSubmit = (values, captchaToken) => {
    const username = values.username;
    getAmpliUserLoginInfo({
      variables: {
        username,
        captcha: {
          origin: 'web',
          token: captchaToken
        }
      }
    });
    onSubmit === null || onSubmit === void 0 || onSubmit(_objectSpread(_objectSpread({}, values), {}, {
      username
    }));
  };

  const formik = useFormik({
    initialValues: {
      username: ''
    },
    validationSchema: getAmpliUserLoginInfoMetadata.schema(schemaOptions),
    onSubmit: values => {
      if (!grecaptcha) {
        throw new Error("Google reCaptcha doesn't exist.");
      }

      grecaptcha.ready(() => {
        grecaptcha.execute(settings.recaptchaSiteKey, {
          action: 'getAmpliUserLoginInfo'
        }).then(token => {
          handleSubmit(values, token);
          return token;
        }).catch(err => {
          console.error(err || 'Captcha UNKNOWN error');
        });
      });
    }
  });
  return {
    formik,
    getAmpliUserLoginInfoMetadata,
    verifyPhoneNumberMetadata
  };
};

export default useGetAmpliUserLoginInfoForm;