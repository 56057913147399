import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useLazyQuery, useMutation, useServiceClient } from '../hooks';
import { VALIDATE_USER_SMS_CODE, RESET_USER_PASSWORD, VERIFY_USER_PHONE_NUMBER, GET_AMPLI_USER_LOGIN_INFO, NOTIFY_STUDENT_BY_TYPE, VALIDATE_NOTIFICATION_CODE, RESET_PASSWORD, GET_POLICIES, CREATE_DEVICE_WHITELIST } from './queries';
import { validateUserSmsCodeSchema, resetUserPasswordSchema, verifyUserPhoneNumberSchema, getAmpliUserLoginInfoSchema } from './schemas';
export const useValidateUserSmsCode = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [mutate, metadata] = useMutation(VALIDATE_USER_SMS_CODE, _objectSpread(_objectSpread({}, options), {}, {
    client,
    fetchPolicy: 'no-cache'
  }));
  return [mutate, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: validateUserSmsCodeSchema
  })];
};
export const useResetUserPassword = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [mutate, metadata] = useMutation(RESET_USER_PASSWORD, _objectSpread(_objectSpread({}, options), {}, {
    client,
    fetchPolicy: 'no-cache'
  }));
  return [mutate, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: resetUserPasswordSchema
  })];
};
export const usePolicies = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [query, metadata] = useLazyQuery(GET_POLICIES, _objectSpread(_objectSpread({}, options), {}, {
    client,
    fetchPolicy: 'no-cache'
  }));
  return [query, _objectSpread({}, metadata)];
};
export const useCreateDeviceWhitelist = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(CREATE_DEVICE_WHITELIST, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const useResetPassword = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [mutate, metadata] = useMutation(RESET_PASSWORD, _objectSpread(_objectSpread({}, options), {}, {
    client,
    fetchPolicy: 'no-cache'
  }));
  return [mutate, _objectSpread({}, metadata)];
};
export const useVerifyUserPhoneNumber = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [mutate, metadata] = useMutation(VERIFY_USER_PHONE_NUMBER, _objectSpread(_objectSpread({}, options), {}, {
    client,
    fetchPolicy: 'no-cache'
  }));
  return [mutate, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: verifyUserPhoneNumberSchema
  })];
};
export const useNotifyStudentByType = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  return useMutation(NOTIFY_STUDENT_BY_TYPE, _objectSpread(_objectSpread({}, options), {}, {
    client,
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const useValidateNotificationCode = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  return useMutation(VALIDATE_NOTIFICATION_CODE, _objectSpread(_objectSpread({}, options), {}, {
    client,
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const useGetAmpliUserLoginInfo = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [query, metadata] = useLazyQuery(GET_AMPLI_USER_LOGIN_INFO, _objectSpread(_objectSpread({}, options), {}, {
    client
  }));
  return [query, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: getAmpliUserLoginInfoSchema
  })];
};