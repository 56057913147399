import { useEffect, useState } from 'react';

const useRegressiveCounter = endDate => {
  const [totalRemainingTimeArray, setTotalRemainingTimeArray] = useState([]);
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  useEffect(() => {
    setInterval(() => {
      calculateRemainingTime(endDate || new Date());
    }, 1000);
  }, []);

  function calculateRemainingTime(date) {
    const today = new Date();
    const timeRemainingInMilliseconds = Math.abs(today - date);
    setTotalRemainingTimeArray([{
      title: 'Dias',
      time: Math.floor(timeRemainingInMilliseconds / day)
    }, {
      title: 'Horas',
      time: Math.floor(timeRemainingInMilliseconds % day / hour)
    }, {
      title: 'Min',
      time: Math.floor(timeRemainingInMilliseconds % hour / minute)
    }, {
      title: 'Seg',
      time: Math.floor(timeRemainingInMilliseconds % minute / second)
    }]);
  }

  return totalRemainingTimeArray;
};

export default useRegressiveCounter;