import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useRef } from 'react';
import useIsInViewport from 'use-is-in-viewport';

const useScroller = _ref => {
  let {
    isInViewportSettings = {},
    scrollIntoViewSettings = {}
  } = _ref;
  const scrollRef = useRef(null);
  const [isInViewport, ref] = useIsInViewport(_objectSpread(_objectSpread({}, isInViewportSettings), {}, {
    target: scrollRef
  }));

  const scroller = () => {
    scrollRef.current.scrollIntoView(_objectSpread({
      behavior: 'smooth',
      block: 'start'
    }, scrollIntoViewSettings));
  };

  return {
    ref,
    scroller,
    isInViewport,
    originalRef: scrollRef
  };
};

export default useScroller;