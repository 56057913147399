import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["language"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { trim, isString, size, isNumber, deburr, toLower } from 'lodash';
import { DateTime, Duration } from 'luxon';
const possibleDurationTypes = ['years', 'quarters', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
export const phoneFormatter = value => {
  if (!isString(value)) {
    throw new Error('Phone should be string, but get: ' + typeof value);
  }

  const phone = value.replace(/[^\d]+/g, '').split('');

  if (size(phone) < 11) {
    return phone.join('');
  }

  phone.splice(0, 0, '(');
  phone.splice(3, 0, ')');
  phone.splice(4, 0, ' ');
  phone.splice(-4, 0, '-');
  return phone.join('');
};
export const boletoCodeFormatter = value => {
  if (!isString(value)) {
    throw new Error('Boleto code should be string, but get: ' + typeof value);
  }

  const isFormatted = value.includes('.');
  if (isFormatted) return value;
  const boleto = trim(value).replace(/[^\d]+/g, '').split('');

  if (size(boleto) < 47) {
    throw new Error('Invalid boleto code size: ' + size(boleto));
  }

  for (let i = 0; i < 30; i += 10) {
    boleto[i + 4] += '.';
    boleto[i + 9] += ' ';
  }

  boleto.splice(-16, 0, ' ');
  return boleto.join('');
};
export const numberFormatter = function (value) {
  let settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const {
    language = 'pt-BR'
  } = settings,
        options = _objectWithoutProperties(settings, _excluded);

  const formatter = new Intl.NumberFormat(language, options);
  return formatter.format(value || 0);
};
export const moneyFormatter = function (value) {
  let settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    currency = 'BRL'
  } = settings;
  return numberFormatter(value, _objectSpread({
    style: 'currency',
    currency
  }, settings));
};
export const addressFormatter = address => {
  return address && address.city && address.state && [address.street, address.number, address.complement, address.neighbourhood, address.city.name, address.state.acronym].filter(a => a).join(', ');
};
export const formatIsoDate = function (isoDate) {
  let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'dd/MM/yyyy';
  return DateTime.fromISO(isoDate).toFormat(format);
};
export const formatIsoToLocaleDate = function (isoDate) {
  let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DATE_SHORT';
  return DateTime.fromISO(isoDate).toLocaleString(DateTime[format] || format);
};
export const formatDuration = (startDate, endDate) => {
  const format = 'dd/MM';
  return `${formatIsoDate(startDate, format)} - ${formatIsoDate(endDate, format)}`;
};
export const formatInterval = function (interval) {
  let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DATE_SHORT';
  let separator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ' - ';
  if (!interval) return '';
  return `${interval.start.toLocaleString(DateTime[format] || format)}${separator}${interval.end.toLocaleString(DateTime[format] || format)}`;
};
export const fullDateLongString = date => {
  if (!date) {
    return '';
  }

  return `${date.day} de ${date.monthLong} de ${date.year}`;
};
export const fullDateLongStringInterval = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return '';
  }

  const longStartDate = fullDateLongString(startDate);
  const longEndtDate = fullDateLongString(endDate);

  if (startDate.year === endDate.year) {
    return `
    ${longStartDate.split(` de ${startDate.year}`)[0]} até ${longEndtDate}`;
  }

  return `${longStartDate} até ${longEndtDate}`;
};
export const intervalInDays = (startDate, endDate) => {
  const diffInMs = new Date(endDate) - new Date(startDate);
  return `${Math.floor(diffInMs / (1000 * 60 * 60 * 24))}`;
};
export const intervalInHours = (startHour, endHour) => {
  const diffInMs = new Date(`1970-01-01 ${endHour}`) - new Date(`1970-01-01 ${startHour}`);
  return `${diffInMs / (1000 * 60 * 60)}`;
};
export const formatDate = function (date) {
  let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DATE_SHORT';
  return date.toLocaleString(DateTime[format] || format);
};
export const formatTimeSeconds = function (seconds) {
  let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'mm:ss';
  return seconds ? Duration.fromObject({
    seconds
  }).toFormat(format) : '00:00';
};
export const cpfFormatter = value => {
  if (!isString(value)) {
    throw new Error('CPF should be string, but get: ' + typeof value);
  }

  const cpf = value.replace(/[^\d]+/g, '').split('');

  if (size(cpf) < 11) {
    throw new Error('Invalid CPF size: ' + size(cpf));
  }

  cpf.splice(3, 0, '.');
  cpf.splice(7, 0, '.');
  cpf.splice(11, 0, '-');
  return cpf.join('');
};
export const cnpjFormatter = value => {
  if (!isString(value)) {
    throw new Error('CNPJ should be string, but get: ' + typeof value);
  }

  const cnpj = value.replace(/[^\d]+/g, '').split('');

  if (size(cnpj) < 14) {
    throw new Error('Invalid CNPJ size: ' + size(cnpj));
  }

  cnpj.splice(2, 0, '.');
  cnpj.splice(6, 0, '.');
  cnpj.splice(10, 0, '/');
  cnpj.splice(15, 0, '-');
  return cnpj.join('');
};
export const onlyNumbersFormatter = value => {
  const ONLY_NUMBERS_REGEX = /[^\d]/g;
  return value.replace(ONLY_NUMBERS_REGEX, '');
};
/**
 * Converts month number in a yearly string
 * @param {number} months - Number of months (must be an integer)
 * @returns {string} Formatted String
 *
 * @example
 *
 * const notEvenAYear = formatMonthsInYearlyString(5);
 * // expected output: "5 meses"
 * const moreThanAYear = formatMonthsInYearlyString(18);
 * // expected output: "1 ano e 6 meses"
 * const exactlyTwoYears = formatMonthsInYearlyString(24);
 * // expected output: "2 anos"
 * const moreThanThreeYears = formatMonthsInYearlyString(43);
 * // expected output: "3 anos e 7 meses"
 */

export const formatMonthsInYearlyString = months => {
  function yearTimeString(months) {
    const timeInYears = Math.floor(months / 12);
    return timeInYears < 1 ? '' : `${timeInYears} ano${timeInYears > 1 ? 's' : ''}`;
  }

  function monthTimeString(months) {
    const monthWord = months % 12 === 1 ? 'mês' : 'meses';
    if (months % 12 === 0) return '';else return months < 12 ? `${months} ${monthWord}` : ` e ${months % 12} ${monthWord}`;
  }

  return months && typeof months === 'number' ? `${yearTimeString(months)}${monthTimeString(months)}` : null;
};
/**
 * Converts a number duration into ISO8601 Duration format
 * @param {number} duration - Duration value
 * @param {string} durationType - Duration type from possible values [
    'years',
    'quarters',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'seconds',
    'milliseconds',
  ]
 * @returns {string} Duration in ISO8601 format
 * @example parseDurationToISO8601Format(6, 'months') => 'P6M';
 */

export const parseDurationToISO8601Format = (duration, durationType) => {
  return duration && durationType && possibleDurationTypes.includes(durationType) && Duration.fromObject({
    [durationType]: duration
  }).toISO();
};
/**
  * Extracts the duration value from a duration string in ISO8601 Duration format
  * @param {string} duration - ISO8601 Duration string
  * @param {string} durationType - What duration type you want to extract from possible values [
     'years',
     'quarters',
     'months',
     'weeks',
     'days',
     'hours',
     'minutes',
     'seconds',
     'milliseconds',
   ]
  * @returns {number} Parsed object
  * @example parseDurationFromISO8601Format('P6M', 'months') => 6;
  */

export const parseDurationFromISO8601Format = (duration, durationType) => {
  if (typeof duration === 'number') return duration;else return duration && durationType && possibleDurationTypes.includes(durationType) && Duration.fromISO(duration)[durationType];
};
/**
 * Gets a price value and return it split into integer and decimal parts
 * @param {(number|string)} price in number or parsedString format
 * @returns {{integer: string, decimal: string}} split object
 * @example
 * splitPrice(149.99) => { integer: '149', decimal: '99' };
 * splitPrice(99) => { integer: '99', decimal: '00' };
 * splitPrice('54.99') => { integer: '54', decimal: '99' };
 */

export const splitPrice = price => {
  if (!isNumber(price) && !isString(price)) throw new Error('Price should be a number or a parsed string, but got: ' + typeof price);
  const priceToFloat = parseFloat(price).toFixed(2);
  if (price === 'NaN') throw new Error('Recieved price string is not a number');
  const splitPrice = priceToFloat.toString().split('.');
  return {
    integer: splitPrice[0],
    decimal: splitPrice[1]
  };
};
/**
 * Returns built image url from S3
 * @param {{baseUrl: string, formats: [string]}} imageObject
 * @param {string} desiredFormat
 * @returns {string}
 */

export const imageURLFormatter = function (imageObject) {
  let desiredFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'original';
  if (!imageObject || !imageObject.baseUrl || imageObject.formats && !imageObject.formats.length || !imageObject.formats.includes(desiredFormat)) return null;
  return `${imageObject.baseUrl}/${desiredFormat}`;
};
export const localDateFormatToIsoDate = value => value.split('/').reverse().join('-');
/**
 * Convert the text to lowercase and into a simple ASCII format
 * @param {string} text
 * @returns Text in lowercase and without special characters
 * @example "Administração" -> "administracao"
 */

export const normalizeText = text => {
  return deburr(toLower(trim(text)));
};
export function pluralizeString(_ref) {
  let {
    value,
    word,
    suffix = 's'
  } = _ref;
  return value == 1 ? word : word + suffix;
}
export const formatSupportedDocumentExtension = extensions => {
  if (!extensions) return;
  return extensions.map(value => value.split('/').pop().toUpperCase()).join(', ').replace(/, ([^,]*)$/, ' e $1');
};