import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["data"],
      _excluded2 = ["data"],
      _excluded3 = ["data", "loading"],
      _excluded4 = ["data", "loading"],
      _excluded5 = ["data", "loading"],
      _excluded6 = ["data", "loading"],
      _excluded7 = ["data", "loading"],
      _excluded8 = ["data", "loading"],
      _excluded9 = ["data", "loading"],
      _excluded10 = ["data", "loading"],
      _excluded11 = ["data", "loading"],
      _excluded12 = ["data", "loading"],
      _excluded13 = ["data", "loading"],
      _excluded14 = ["data"],
      _excluded15 = ["data"],
      _excluded16 = ["variables", "update"],
      _excluded17 = ["variables", "update"],
      _excluded18 = ["variables", "update"],
      _excluded19 = ["data"],
      _excluded20 = ["data"],
      _excluded21 = ["data"],
      _excluded22 = ["data"],
      _excluded23 = ["data"],
      _excluded24 = ["data"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useLazyQuery, useMutation, useQuery, useServiceClient } from '../hooks';
import { CHOOSE_SUBJECT_ELECTIVE, GENERATE_CONTENT_PDF, CREATE_ENROLLMENT_RECEIPT, CREATE_NEW_ORDER, DELETE_STUDENT_CREDIT_CARD, DISABLE_RECURRING_PAYMENT, ENABLE_RECURRING_PAYMENT, FIND_LEARNING_UNIT_ENROLLMENTS, FIND_STUDENT_COURSE_ENROLLMENTS, FIND_STUDENT_COURSE_ENROLLMENTS_CALENDAR_PAGE, GET_STUDENT_COURSE_ENROLLMENT_GRADE_HOME_PAGE, FIND_STUDENT_COURSE_ENROLLMENTS_SUBJECT_PAGE, FIND_STUDENT_COURSE_TYPE, FIND_STUDENT_MY_COURSE_ENROLLMENTS_PAGE, GET_ACO_CATEGORIES, GET_ACO_HISTORY, GET_ACO_SUMMARY, GET_COURSE_ENROLLMENTS_PROGRESS, GET_LAST_DONE_ASSIGNMENTS, GET_LAST_ORDER_STUDENT, GET_REASON_BY_STATUS_FOR_STUDENT, GET_STUDENT, GET_STUDENT_CREDIT_CARDS, GET_STUDENT_PAYMENT_PLAN, GET_STUDENT_USER_LOGIN_DATA, GET_DYNAMICS_LOGIN_DATA, GET_STUDENT_USER_LOGIN_INFO, GET_SUBJECT_ENROLLMENT, LIST_ASSIGNMENT_FILES, LIST_AVAILABLE_EXAMS_BY_COURSE_ENROLLMENT_ID, SEND_ACO, UPLOAD_ASSIGNMENT_DOCUMENT, UPLOAD_STUDENT_DOCUMENT, UPDATE_STUDENT_PHOTO, GET_STUDENT_CONTACTS, UPDATE_STUDENT_CONTACTS, GET_STUDENT_REGISTRATION, GET_LEARNING_UNIT_TITLE, GET_STUDENT_COURSE_ENROLLMENT_SUBJECT_PAGE, GET_SUBJECT_ENROLLMENT_FOR_SUBJECT_PAGE, UPLOAD_ASSIGNMENT_DOCUMENT_PRATICAL_ACTIVITIES, REFUSE_SUBJECT_ELECTIVE, GET_ALL_DEVICES, REMOVE_WHITELIST, GET_STUDENT_OR_LEGACY } from './queries';
import { GET_COURSE_ENROLLMENT_HOME, GET_SUBJECT_ENROLLMENTS_FOR_SYLLABUS_PAGE } from '../course/queries';
import { getStudentUserLoginInfoSchema, uploadAssignmentDocumentSchema, getUpdateStudentContactSchema, uploadStudentDocumentSchema } from './schemas';
const cacheFetchPolicy = 'cache-first';
export const useGetStudent = function (studentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery = useQuery(GET_STUDENT, _objectSpread(_objectSpread({
    skip: !studentId
  }, options), {}, {
    variables: {
      studentId
    },
    fetchPolicy: 'no-cache'
  })),
        {
    data
  } = _useQuery,
        rest = _objectWithoutProperties(_useQuery, _excluded);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export const useGetAllDevices = function (userId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery2 = useQuery(GET_ALL_DEVICES, _objectSpread(_objectSpread({
    skip: !userId
  }, options), {}, {
    variables: {
      userId
    },
    fetchPolicy: 'no-cache'
  })),
        {
    data
  } = _useQuery2,
        rest = _objectWithoutProperties(_useQuery2, _excluded2);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export const useRemoveWhitelist = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const [mutation, metadata] = useMutation(REMOVE_WHITELIST, options);
  return [mutation, metadata];
};
export const useGetStudentOrLegacy = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  return useLazyQuery(GET_STUDENT_OR_LEGACY, _objectSpread(_objectSpread({}, options), {}, {
    client,
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const getStudentLoginData = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = useServiceClient('publicClient'); // eslint-disable-next-line react-hooks/rules-of-hooks

  return useLazyQuery(GET_STUDENT_USER_LOGIN_DATA, _objectSpread(_objectSpread({}, options), {}, {
    client,
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const getDynamicsLoginData = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = useServiceClient('publicClient'); // eslint-disable-next-line react-hooks/rules-of-hooks

  const [mutate, metadata] = useMutation(GET_DYNAMICS_LOGIN_DATA, _objectSpread(_objectSpread({}, options), {}, {
    client,
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
  return [mutate, _objectSpread({}, metadata)];
};
export const useGetStudentUserLoginInfo = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient('publicClient');
  const [query, metadata] = useLazyQuery(GET_STUDENT_USER_LOGIN_INFO, _objectSpread(_objectSpread({}, options), {}, {
    client
  }));
  return [query, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: getStudentUserLoginInfoSchema
  })];
};
export const useFindStudentCourseEnrollments = function (studentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery3 = useQuery(FIND_STUDENT_COURSE_ENROLLMENTS, _objectSpread(_objectSpread({
    skip: !studentId
  }, options), {}, {
    variables: {
      studentId
    },
    fetchPolicy: 'no-cache'
  })),
        {
    data,
    loading
  } = _useQuery3,
        rest = _objectWithoutProperties(_useQuery3, _excluded3);

  const courseEnrollments = loading || !data ? [] : data.data;
  return _objectSpread({
    data: courseEnrollments,
    loading
  }, rest);
};
export const useGetLearningUnitTitle = function (subjectEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery4 = useQuery(GET_LEARNING_UNIT_TITLE, _objectSpread(_objectSpread({
    skip: !subjectEnrollmentId
  }, options), {}, {
    variables: {
      subjectEnrollmentId
    }
  })),
        {
    data,
    loading
  } = _useQuery4,
        rest = _objectWithoutProperties(_useQuery4, _excluded4);

  const learningUnit = loading || !data ? [] : data.data;
  return _objectSpread({
    data: learningUnit,
    loading
  }, rest);
};
export const useFindStudentCourseEnrollmentsSubjectPage = function (studentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery5 = useQuery(FIND_STUDENT_COURSE_ENROLLMENTS_SUBJECT_PAGE, _objectSpread(_objectSpread({
    skip: !studentId
  }, options), {}, {
    variables: {
      studentId
    }
  })),
        {
    data,
    loading
  } = _useQuery5,
        rest = _objectWithoutProperties(_useQuery5, _excluded5);

  const courseEnrollments = loading || !data ? [] : data.data;
  return _objectSpread({
    data: courseEnrollments,
    loading
  }, rest);
};
export const useGetStudentCourseEnrollmentSubjectPage = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery6 = useQuery(GET_STUDENT_COURSE_ENROLLMENT_SUBJECT_PAGE, _objectSpread(_objectSpread({
    skip: !courseEnrollmentId
  }, options), {}, {
    variables: {
      courseEnrollmentId
    },
    fetchPolicy: cacheFetchPolicy
  })),
        {
    data,
    loading
  } = _useQuery6,
        rest = _objectWithoutProperties(_useQuery6, _excluded6);

  const courseEnrollment = loading || !data ? {} : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading
  }, rest);
};
export const useGetSubjectEnrollmentForSubjectPage = function (subjectEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery7 = useQuery(GET_SUBJECT_ENROLLMENT_FOR_SUBJECT_PAGE, _objectSpread(_objectSpread({
    skip: !subjectEnrollmentId
  }, options), {}, {
    variables: {
      subjectEnrollmentId
    },
    fetchPolicy: cacheFetchPolicy
  })),
        {
    data,
    loading
  } = _useQuery7,
        rest = _objectWithoutProperties(_useQuery7, _excluded7);

  const subjectEnrollment = loading || !data ? {} : data.data;
  return _objectSpread({
    data: subjectEnrollment,
    loading
  }, rest);
};
export const useFindStudentCourseEnrollmentsCalendarPage = function (studentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery8 = useQuery(FIND_STUDENT_COURSE_ENROLLMENTS_CALENDAR_PAGE, _objectSpread(_objectSpread({
    skip: !studentId
  }, options), {}, {
    variables: {
      studentId
    }
  })),
        {
    data,
    loading
  } = _useQuery8,
        rest = _objectWithoutProperties(_useQuery8, _excluded8);

  const courseEnrollments = loading || !data ? [] : data.data;
  return _objectSpread({
    data: courseEnrollments,
    loading
  }, rest);
};
export const useFindStudentCourseEnrollmentsGradeHomePage = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery9 = useQuery(GET_STUDENT_COURSE_ENROLLMENT_GRADE_HOME_PAGE, _objectSpread(_objectSpread({
    skip: !courseEnrollmentId
  }, options), {}, {
    variables: {
      courseEnrollmentId
    }
  })),
        {
    data,
    loading
  } = _useQuery9,
        rest = _objectWithoutProperties(_useQuery9, _excluded9);

  const courseEnrollment = loading || !data ? {} : data.data;
  return _objectSpread({
    data: courseEnrollment,
    loading
  }, rest);
};
export const useFindStudentCourseEnrollmentsProgress = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery10 = useQuery(GET_COURSE_ENROLLMENTS_PROGRESS, _objectSpread(_objectSpread({
    skip: !courseEnrollmentId
  }, options), {}, {
    variables: {
      courseEnrollmentId
    }
  })),
        {
    data,
    loading
  } = _useQuery10,
        rest = _objectWithoutProperties(_useQuery10, _excluded10);

  const courseEnrollments = loading || !data ? [] : data.data;
  return _objectSpread({
    data: courseEnrollments,
    loading
  }, rest);
};
export const useFindStudentCourseType = function (studentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery11 = useQuery(FIND_STUDENT_COURSE_TYPE, _objectSpread(_objectSpread({
    skip: !studentId
  }, options), {}, {
    variables: {
      studentId
    }
  })),
        {
    data,
    loading
  } = _useQuery11,
        rest = _objectWithoutProperties(_useQuery11, _excluded11);

  const courseEnrollments = loading || !data ? [] : data.data;
  return _objectSpread({
    data: courseEnrollments,
    loading
  }, rest);
};
export const useFindStudentMyCourseEnrollmentsPage = function (studentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery12 = useQuery(FIND_STUDENT_MY_COURSE_ENROLLMENTS_PAGE, _objectSpread(_objectSpread({
    skip: !studentId
  }, options), {}, {
    variables: {
      studentId
    }
  })),
        {
    data,
    loading
  } = _useQuery12,
        rest = _objectWithoutProperties(_useQuery12, _excluded12);

  const courseEnrollments = loading || !data ? [] : data.data;
  return _objectSpread({
    data: courseEnrollments,
    loading
  }, rest);
};
export const useFindLearningUnitEnrollments = function (subjectEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery13 = useQuery(FIND_LEARNING_UNIT_ENROLLMENTS, _objectSpread(_objectSpread({
    skip: !subjectEnrollmentId
  }, options), {}, {
    variables: {
      subjectEnrollmentId
    }
  })),
        {
    data,
    loading
  } = _useQuery13,
        rest = _objectWithoutProperties(_useQuery13, _excluded13);

  const learningUnitEnrollments = loading || !data ? [] : data.data;
  return _objectSpread({
    data: learningUnitEnrollments,
    loading
  }, rest);
};
export const useUploadStudentDocument = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const [mutation, metadata] = useMutation(UPLOAD_STUDENT_DOCUMENT, options);
  return [mutation, metadata, uploadStudentDocumentSchema];
};
export const useRefuseSubjectElective = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const [mutation, metadata] = useMutation(REFUSE_SUBJECT_ELECTIVE, _objectSpread({}, options));
  return [mutation, metadata];
};
export const useUpdateStudentPhoto = function (photo) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [mutation, metadata] = useMutation(UPDATE_STUDENT_PHOTO, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      photo: photo
    },
    context: {
      autoHandle: true
    }
  }));
  return [mutation, metadata];
};
export const useSendAco = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(SEND_ACO, options);
};
export const useUploadAssignmentDocument = function (assignmentId, context) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const [mutation, metadata] = useMutation(UPLOAD_ASSIGNMENT_DOCUMENT, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      assignmentId
    }
  }));
  return [mutation, metadata, uploadAssignmentDocumentSchema];
};
export const useUploadAssignmentDocumentForPraticalActivities = function (assignmentId, context) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const [mutation, metadata] = useMutation(UPLOAD_ASSIGNMENT_DOCUMENT_PRATICAL_ACTIVITIES, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      assignmentId,
      context
    }
  }));
  return [mutation, metadata, uploadAssignmentDocumentSchema];
};
export const chooseSubjectElective = function (courseEnrollmentId, subjectEnrollmentId, subjectId, studentId) {
  let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [mutation, metadata] = useMutation(CHOOSE_SUBJECT_ELECTIVE, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      subjectEnrollmentId,
      subjectId
    },
    refetchQueries: [{
      query: FIND_STUDENT_COURSE_ENROLLMENTS,
      variables: {
        studentId
      }
    }, ...(courseEnrollmentId ? [{
      query: GET_SUBJECT_ENROLLMENTS_FOR_SYLLABUS_PAGE,
      variables: {
        courseEnrollmentId
      }
    }, {
      query: GET_COURSE_ENROLLMENT_HOME,
      variables: {
        courseEnrollmentId
      }
    }] : [])]
  }));
  return [mutation, metadata];
};
export const useGenerateContentPDF = function (subjectEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [query, {
    loading,
    called,
    data,
    error
  }] = useLazyQuery(GENERATE_CONTENT_PDF, _objectSpread({
    skip: !subjectEnrollmentId,
    variables: {
      subjectEnrollmentId
    }
  }, options));
  return {
    getData: () => {
      if (called && error && !loading || !called) {
        query();
      }
    },
    data,
    error,
    loading
  };
};
export const useGetStudentPaymentPlan = function (enrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery14 = useQuery(GET_STUDENT_PAYMENT_PLAN, _objectSpread(_objectSpread({
    skip: !enrollmentId
  }, options), {}, {
    variables: {
      enrollmentId
    },
    fetchPolicy: 'no-cache'
  })),
        {
    data
  } = _useQuery14,
        rest = _objectWithoutProperties(_useQuery14, _excluded14);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export const useGetStudentCreditCards = function (enrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery15 = useQuery(GET_STUDENT_CREDIT_CARDS, _objectSpread(_objectSpread({
    skip: !enrollmentId
  }, options), {}, {
    variables: {
      enrollmentId
    }
  })),
        {
    data
  } = _useQuery15,
        rest = _objectWithoutProperties(_useQuery15, _excluded15);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};

const changeLocalRecurringStudentCreditCard = (id, newValue, _ref) => {
  let {
    cache
  } = _ref;
  const cacheId = cache.identify({
    __typename: 'StudentCreditCard',
    id
  });
  cache.modify({
    id: cacheId,
    fields: {
      isRecurring() {
        return newValue;
      }

    }
  });
};

const disableLocalRecurringStudentCreditCard = (variables, _ref2) => {
  let {
    client,
    cache
  } = _ref2;
  const {
    data
  } = client.readQuery({
    query: GET_STUDENT_CREDIT_CARDS,
    variables
  });

  for (const studentCreditCard of data) {
    changeLocalRecurringStudentCreditCard(studentCreditCard.id, false, {
      cache
    });
  }
};

export const useEnableRecurringPayment = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const [_mutate, metadata] = useMutation(ENABLE_RECURRING_PAYMENT, options);

  const mutate = _options => {
    const {
      variables = {},
      update
    } = _options,
          options = _objectWithoutProperties(_options, _excluded16);

    return _mutate(_objectSpread(_objectSpread({}, options), {}, {
      variables,

      update(cache, result) {
        var _result$data;

        if (result !== null && result !== void 0 && (_result$data = result.data) !== null && _result$data !== void 0 && _result$data.data && !(result !== null && result !== void 0 && result.errors)) {
          changeLocalRecurringStudentCreditCard(variables.recurring.studentCreditCardId, true, {
            cache
          });
        }

        update && update(cache, result);
      }

    }));
  };

  return [mutate, metadata];
};
export const useDisableRecurringPayment = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = useServiceClient();
  const [_mutate, metadata] = useMutation(DISABLE_RECURRING_PAYMENT, options);

  const mutate = _options => {
    const {
      variables = {},
      update
    } = _options,
          options = _objectWithoutProperties(_options, _excluded17);

    return _mutate(_objectSpread(_objectSpread({}, options), {}, {
      variables,

      update(cache, result) {
        var _result$data2;

        if (result !== null && result !== void 0 && (_result$data2 = result.data) !== null && _result$data2 !== void 0 && _result$data2.data && !(result !== null && result !== void 0 && result.errors)) {
          disableLocalRecurringStudentCreditCard(variables, {
            client,
            cache
          });
        }

        update && update(cache, result);
      }

    }));
  };

  return [mutate, metadata];
};

const deleteLocalStudentCreditCard = (id, _ref3) => {
  let {
    cache
  } = _ref3;
  const cacheId = cache.identify({
    __typename: 'StudentCreditCard',
    id
  });
  cache.evict({
    id: cacheId
  });
  cache.gc();
};

export const useDeleteStudentCreditCard = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const [_mutate, metadata] = useMutation(DELETE_STUDENT_CREDIT_CARD, options);

  const mutate = _options => {
    const {
      variables = {},
      update
    } = _options,
          options = _objectWithoutProperties(_options, _excluded18);

    return _mutate(_objectSpread(_objectSpread({}, options), {}, {
      variables,

      update(cache, result) {
        var _result$data3;

        if (result !== null && result !== void 0 && (_result$data3 = result.data) !== null && _result$data3 !== void 0 && _result$data3.data && !(result !== null && result !== void 0 && result.errors)) {
          deleteLocalStudentCreditCard(variables.studentCreditCardId, {
            cache
          });
        }

        update && update(cache, result);
      }

    }));
  };

  return [mutate, metadata];
};
export const useCreateEnrollmentReceipt = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(CREATE_ENROLLMENT_RECEIPT, _objectSpread(_objectSpread({}, options), {}, {
    context: {
      autoHandle: true
    }
  }));
};
export const useListAvailableExams = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery16 = useQuery(LIST_AVAILABLE_EXAMS_BY_COURSE_ENROLLMENT_ID, _objectSpread(_objectSpread({
    skip: !courseEnrollmentId
  }, options), {}, {
    variables: {
      courseEnrollmentId
    }
  })),
        {
    data
  } = _useQuery16,
        rest = _objectWithoutProperties(_useQuery16, _excluded19);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export const useLazyListAvailableExams = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [query, _ref4] = useLazyQuery(LIST_AVAILABLE_EXAMS_BY_COURSE_ENROLLMENT_ID, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId
    }
  }));

  const {
    data
  } = _ref4,
        rest = _objectWithoutProperties(_ref4, _excluded20);

  return [query, _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest)];
};
export const useGetSubjectEnrollment = function (subjectEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery17 = useQuery(GET_SUBJECT_ENROLLMENT, _objectSpread(_objectSpread({
    skip: !subjectEnrollmentId
  }, options), {}, {
    variables: {
      subjectEnrollmentId
    }
  })),
        {
    data
  } = _useQuery17,
        rest = _objectWithoutProperties(_useQuery17, _excluded21);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export const useGetLastDoneAssignments = function (courseEnrollmentId) {
  let numberOfAssignments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  const _useQuery18 = useQuery(GET_LAST_DONE_ASSIGNMENTS, _objectSpread(_objectSpread({
    skip: !courseEnrollmentId
  }, options), {}, {
    variables: {
      courseEnrollmentId,
      numberOfAssignments
    }
  })),
        {
    data
  } = _useQuery18,
        rest = _objectWithoutProperties(_useQuery18, _excluded22);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export const useListAssignmentFiles = function (assignmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery19 = useQuery(LIST_ASSIGNMENT_FILES, _objectSpread(_objectSpread({
    skip: !assignmentId
  }, options), {}, {
    variables: {
      assignmentId
    }
  })),
        {
    data
  } = _useQuery19,
        rest = _objectWithoutProperties(_useQuery19, _excluded23);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export const useGetAcoCategories = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useLazyQuery(GET_ACO_CATEGORIES, options);
};
export const useGetACOHistory = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useLazyQuery(GET_ACO_HISTORY, _objectSpread({
    variables: {
      courseEnrollmentId
    },
    context: {
      autoHandle: true
    }
  }, options));
};
export const useGetACOSummary = function (courseEnrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useLazyQuery(GET_ACO_SUMMARY, _objectSpread({
    variables: {
      courseEnrollmentId
    },
    context: {
      autoHandle: true
    }
  }, options));
};
export const useReasonByStatusForStudent = function (status) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery20 = useQuery(GET_REASON_BY_STATUS_FOR_STUDENT, _objectSpread(_objectSpread({
    skip: !status
  }, options), {}, {
    variables: {
      status
    },
    context: {
      autoHandle: true
    }
  })),
        {
    data
  } = _useQuery20,
        rest = _objectWithoutProperties(_useQuery20, _excluded24);

  return _objectSpread({
    data: data === null || data === void 0 ? void 0 : data.data
  }, rest);
};
export const useGetLastOrderStudent = function (courseEnrollmentId, orderSubject) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return useQuery(GET_LAST_ORDER_STUDENT, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId,
      orderSubject
    },
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const useLazyGetLastOrderStudent = function (courseEnrollmentId, orderSubject) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return useLazyQuery(GET_LAST_ORDER_STUDENT, _objectSpread({
    variables: {
      courseEnrollmentId,
      orderSubject
    },
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }, options));
};
export const useCreateNewOrderStudent = function (courseEnrollmentId, orderSubject) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const [mutation, metadata] = useMutation(CREATE_NEW_ORDER, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      courseEnrollmentId,
      orderSubject
    },
    context: {
      autoHandle: true
    }
  }));
  return [mutation, metadata];
};
export const useGetStudentContacts = function (enrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useQuery(GET_STUDENT_CONTACTS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      enrollmentId
    },
    context: {
      autoHandle: true
    },
    fetchPolicy: 'no-cache'
  }));
};
export const useUpdateStudentContacts = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let ignoreUseeffectError = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const [mutate, metadata] = useMutation(UPDATE_STUDENT_CONTACTS, _objectSpread({}, options), ignoreUseeffectError);
  return [mutate, _objectSpread(_objectSpread({}, metadata), {}, {
    schema: getUpdateStudentContactSchema
  })];
};
export const useGetStudentRegistration = function (enrollmentId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return useQuery(GET_STUDENT_REGISTRATION, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      enrollmentId
    },
    context: {
      autoHandle: true
    }
  }));
};