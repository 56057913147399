import React, { cloneElement } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  ImageField,
  ReferenceArrayField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
    maxHeight: '100%',
  },
}));

const StringToLabelObject = ({ record, children, ...rest }) =>
  cloneElement(children, {
    record: { label: record },
    ...rest,
  });

export const ProductOfferShow = (props) => {
  const styles = useStyles();
  return (
    <Show title="Novidades - Show" {...props}>
      <SimpleShowLayout>
        <TextField label="Título" source="name" />
        <TextField label="Texto 1" source="firstDescription" />
        <TextField label="Texto 2" source="secondDescription" />
        <TextField label="Texto 3" source="thirdDescription" />
        <TextField label="CTA" source="cta" />
        <TextField label="Link" source="urlWebPage" />
        <ReferenceArrayField
          label="Curso de Matrícula"
          reference="course"
          source="courseIds"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ImageField
          label="Imagem da Novidade"
          source="thumbnail"
          classes={{ image: styles.image }}
        />
        <ArrayField source="tags">
          <SingleFieldList>
            <StringToLabelObject>
              <ChipField source="label" />
            </StringToLabelObject>
          </SingleFieldList>
        </ArrayField>
        <TextField label="Exibir em" source="displayedInTranslated" />
        <ReferenceArrayField
          label="Mostrar em Cursos"
          reference="course"
          source="displayedInCourses"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField label="Tipo de curso" source="formattedCourseDegree" />
      </SimpleShowLayout>
    </Show>
  );
};
