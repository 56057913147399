import gql from 'graphql-tag';
import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_ONE,
  UPDATE,
} from 'react-admin';
import {
  createOne,
  deleteOne,
  getList,
  getMany,
  getOne,
  updateOne,
} from '@ampli/admin-core';

// ALWAYS PUT URL ATTRIBUTE AS LAST, BECAUSE WHEN IT'S EXPORTED IT SHOULD BE THE LAST COLUMN
const ENTITY = `
  id
  birthDate
  cpf
  email
  name
  phone
  sellerUnitId
  sellerCategoryId
  sellerCoupons {
    code
  }
  url
`;

const LIST_QUERY = gql`
  query ListSeller($pagination: Pagination, $filter: Filter, $sort: Sort) {
    connection: listSeller(pagination: $pagination, filter: $filter, sort: $sort) {
      data {
        ${ENTITY}
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

const GET_ONE_QUERY = gql`
  query GetSeller($id: ID) {
    data: getSeller(id: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetSellers($ids: [ID!]!) {
    data: getManySellers (
      ids: $ids
    ) {
      ${ENTITY}
    }
  }
`;

const CREATE_ONE_QUERY = gql`
  mutation CreateSeller($data: SellerData) {
    data: createSeller(seller: $data) {
      ${ENTITY}
    }
  }
`;

const cleanCpf = (cpf) => cpf.replace(/\./g, '').replace(/-/g, '');

const createOneParseObject = (result) => result;

const createOneParseParams = (params) => {
  params.data.cpf = cleanCpf(params.data.cpf);
  return params;
};

const DELETE_ONE_QUERY = gql`
  mutation DeleteSeller($id: ID!) {
    data: deleteSeller(id: $id)
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateSeller($id: ID, $data: UpdateSellerData) {
    data: updateSeller(id: $id, seller: $data) {
      ${ENTITY}
    }
  }
`;

const parseeObject = (result) => {
  return {
    ...result,
    sellerCoupons: result.sellerCoupons.map((c) => c.code),
  };
};

const updateParseParams = (params) => {
  params.data.cpf = cleanCpf(params.data.cpf);
  params.data.sellerCoupons = params.data.sellerCoupons.map((c) => ({
    code: c,
    sellerId: params.data.id,
  }));

  delete params.data.id;
  delete params.data.url;
  delete params.data.couponCode;

  return params;
};

export default {
  [CREATE]: createOne(
    CREATE_ONE_QUERY,
    createOneParseObject,
    createOneParseParams
  ),
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
  [GET_LIST]: getList(LIST_QUERY, parseeObject),
  [GET_MANY]: getMany(GET_MANY_QUERY, parseeObject),
  [GET_ONE]: getOne(GET_ONE_QUERY, parseeObject),
  [UPDATE]: updateOne(UPDATE_ONE_QUERY, parseeObject, updateParseParams),
};
