import React from 'react';
import {
  Edit,
  ReferenceField,
  SimpleForm,
  NumberInput,
  TextField,
  Toolbar,
  SaveButton,
} from 'react-admin';

const SaveOnlyEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const DefaultCoursePriceEdit = (props) => (
  <Edit undoable={false} title="Edição de preço do curso" {...props}>
    <SimpleForm redirect="list" toolbar={<SaveOnlyEditToolbar />}>
      <ReferenceField
        label="Curso"
        source="courseId"
        reference="course"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Tipo do curso"
        source="courseId"
        reference="course"
        link={false}
      >
        <TextField source="courseType.name" />
      </ReferenceField>
      <NumberInput label="Primeira mensalidade" source="firstInstalmentPrice" />
      <NumberInput label="Demais mensalidades" source="otherInstalmentsPrice" />
    </SimpleForm>
  </Edit>
);
