import axios from 'axios';
export async function request(config) {
  try {
    const result = await axios(config);

    if (!result.data) {
      throw new Error('Request data not found');
    }

    if (result.data.error) {
      throw new Error(result.data.error);
    }

    return result.data;
  } catch (e) {
    console.error(e);
  }
}
export async function graphQLRequest(url, query) {
  let variables = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  try {
    const result = await request({
      url,
      method: 'POST',
      data: {
        query: query.loc.source.body,
        variables
      },
      headers: {
        Authorization: 'Basic cG9ydGFsOnNlY3JldA==',
        'Access-Control-Allow-Credentials': true
      }
    });
    return result.data;
  } catch (e) {
    console.error(e);
  }
}