import { useFormik } from '@ampli/utils';
import useVerifyUserPhoneNumber from './use-verify-phone-number';

const useVerifyUserPhoneNumberForm = _ref => {
  let {
    settings = {},
    schemaOptions = {},
    onSubmit,
    onError,
    onCompleted
  } = _ref;
  const {
    handleVerifyPhoneNumber,
    metadata: {
      data,
      called,
      loading,
      error,
      schema
    }
  } = useVerifyUserPhoneNumber({
    settings,
    onSubmit,
    onError,
    onCompleted
  });
  const formik = useFormik({
    initialValues: {
      userId: ''
    },
    validationSchema: schema(schemaOptions),
    onSubmit: handleVerifyPhoneNumber
  });
  return {
    formik,
    loading,
    called,
    error,
    data
  };
};

export default useVerifyUserPhoneNumberForm;