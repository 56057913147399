import React, { useState } from 'react';
import {
  Edit,
  AutocompleteInput,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  FileField,
  FileInput,
  TextField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  titleTagWarning: {
    color: 'red',
    fontSize: '12px',
    maxWidth: '80%',
    marginTop: '-10%',
  },
  titleTagCountSpan: {
    textAlign: 'end',
    marginTop: '-8%',
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const CourseSEOEdit = (props) => {
  const styles = useStyles();
  const [titleTagCount, setTitleTagCount] = useState(0);
  const [initialValidation, setInitialValidation] = useState(false);
  const [newDocumentFile, setNewDocumentFile] = useState('');

  const onDocumentChange = (event) => {
    setNewDocumentFile(event);
  };

  const onTagTitleChange = (event) => {
    setTitleTagCount(event.target.value.length);
    setInitialValidation(true);
  };

  return (
    <Edit title="SEO de Curso - Edit" undoable={false} {...props}>
      <SimpleForm redirect="list">
        <div className={styles.textContent}>
          <TextInput
            onChange={onTagTitleChange}
            label="Tag de Titulo"
            source="titleTag"
          />
          {initialValidation && (
            <span className={styles.titleTagCountSpan}>{titleTagCount}</span>
          )}
          {titleTagCount > 60 && (
            <span className={styles.titleTagWarning}>
              É recomendado que a quantidade de caracteres não exceda 60
            </span>
          )}
        </div>
        <FileInput
          label="Imagem para vitrine"
          name="icon"
          accept=".svg"
          source="file"
          multiple={false}
          validate={required()}
          onChange={onDocumentChange}
        >
          <FileField source="src" title="title" />
        </FileInput>
        {newDocumentFile === '' ? (
          <TextField label="Imagem atual" source="courseCardImageUrl" />
        ) : null}
        <ReferenceInput
          label="Curso"
          source="courseId"
          reference="course"
          perPage={1000}
          sort={{ field: 'name', order: 'ASC' }}
          validate={[required()]}
        >
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <ReferenceArrayInput
          label="Aplicar nas Seções"
          source="sectionsIds"
          reference="section"
        >
          <AutocompleteArrayInput
            source="sectionLabelName"
            optionText="sectionLabelName"
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};
