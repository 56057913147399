import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["onCompleted", "onError"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useRef, useEffect, useState } from 'react';
import { useMutation as useMutationApollo } from '@apollo/client';
import useServiceClient from './use-service-client';

const useMutation = function (mutation) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let ignoreUseffectError = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const client = useServiceClient();
  const [times, setTimes] = useState(0);
  const timesRef = useRef(times);

  const {
    onCompleted,
    onError
  } = options,
        restOptions = _objectWithoutProperties(options, _excluded);

  const [_mutate, metadata] = useMutationApollo(mutation, _objectSpread(_objectSpread({
    client
  }, restOptions), {}, {
    onCompleted: response => {
      if (response && !response.errors && response.data) {
        onCompleted && onCompleted(response);
      } else if (!response && ignoreUseffectError) {
        onError && onError(metadata.error);
      }
    }
  }));

  const mutate = function () {
    setTimes(times + 1);
    return _mutate(...arguments);
  };

  useEffect(() => {
    if (timesRef.current !== times && !metadata.loading && metadata.error) {
      timesRef.current = times;

      if (!ignoreUseffectError) {
        onError && onError(metadata.error);
      }
    }
  }, [times, timesRef, metadata.loading, metadata.error, onError, ignoreUseffectError]);
  return [mutate, metadata];
};

export default useMutation;