import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

export const BannerList = (props) => (
  <List title="Banner - List" bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <TextField label="Banner" source="description" />
    </Datagrid>
  </List>
);
