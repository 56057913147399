import { useFormik } from '@ampli/utils';
import { useValidateUserSmsCode } from '@ampli/services';

const useValidateCodeForm = _ref => {
  let {
    schemaOptions = {},
    onSubmit,
    onError,
    onCompleted
  } = _ref;
  const [mutate, {
    data,
    called,
    loading,
    error,
    schema
  }] = useValidateUserSmsCode({
    onError,
    onCompleted: _ref2 => {
      let {
        data
      } = _ref2;

      if (data.status !== 'VALID') {
        onError === null || onError === void 0 || onError(data.status);
      } else {
        onCompleted === null || onCompleted === void 0 || onCompleted(data);
      }
    }
  });

  const handleSubmit = values => {
    const userId = values.userId;
    const phoneNumberVerification = values.phoneNumberVerification;
    mutate({
      variables: {
        userId,
        phoneNumberVerification
      }
    });
    onSubmit === null || onSubmit === void 0 || onSubmit();
  };

  const formik = useFormik({
    initialValues: {
      userId: '',
      phoneNumberVerification: {
        verificationId: '',
        code: ''
      }
    },
    validationSchema: schema(schemaOptions),
    onSubmit: handleSubmit
  });
  return {
    formik,
    loading,
    called,
    error,
    data
  };
};

export default useValidateCodeForm;