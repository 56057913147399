import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  RadioButtonGroupInput,
  required,
  FileInput,
  FileField,
} from 'react-admin';
import { courseDegrees } from './common';

export const CourseCategoryCreate = (props) => {
  return (
    <Create title="Criação de Categorias de Curso" {...props}>
      <SimpleForm redirect="list">
        <TextInput
          label="Nome da Categoria"
          source="name"
          validate={required()}
        />
        <BooleanInput label="Exibir" source="visible" />
        <BooleanInput label="Categoria Principal" source="main" />
        <RadioButtonGroupInput
          source="courseDegree"
          label="Nível de curso"
          choices={courseDegrees}
          validate={required()}
        />
        <FileInput
          label="Icone"
          name="icon"
          accept=".svg"
          source="file"
          multiple={false}
          validate={required()}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
