import React from 'react';
import {
  EmailField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
  ArrayField,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
} from 'react-admin';

export const SellerShow = (props) => (
  <Show title="Vendedor" {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField label="Nome" source="name" />
      <TextField label="CPF" source="cpf" />
      <EmailField source="email" />
      <TextField label="Data de Nascimento" source="birthDate" />
      <TextField label="Telefone" source="phone" />
      <UrlField label="Url" source="url" target="_blank" />
      <ReferenceField
        label="Unidade"
        source="sellerUnitId"
        reference="sellerUnit"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Categoria"
        source="sellerCategoryId"
        reference="sellerCategory"
      >
        <TextField source="name" />
      </ReferenceField>
      <ArrayField label="Cupons do Vendedor" source="sellerCoupon">
        <SingleFieldList>
          <ChipField label="Código do cupom" source="code" />
        </SingleFieldList>
      </ArrayField>
      <ReferenceArrayField
        label="Cupons"
        reference="coupon"
        source="sellerCoupons"
      >
        <SingleFieldList>
          <ChipField source="code" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);
