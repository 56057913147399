import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

export const CourseSEOList = (props) => (
  <List title="SEO de Curso - List" {...props} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField label="Tag de Titulo" source="titleTag" />
      <ReferenceField
        label="Curso"
        source="courseId"
        reference="course"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
