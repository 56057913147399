import React, { useState } from 'react';
import { FormTab, TextInput, TextField, ReferenceInput } from 'react-admin';
import { validation } from '../views/partner/common';
import { bool } from '@ampli/utils';

import { SelectImageInput } from '@ampli/admin-core';
import HtmlInput from './html-input';

const PartnerFormTab = ({ editing = false, ...props }) => {
  const [filter, setFilter] = useState({ tags: '' });

  const onChangeFilterImage = (value) => {
    setFilter({ tags: value });
  };
  return (
    <FormTab label="Parceiro" {...props}>
      {editing && <TextField source="id" label="ID" />}
      <TextInput source="name" label="Parceiro" validate={validation.name} />
      <HtmlInput
        source="description"
        label="Descrição"
        fullWidth
        validate={validation.description}
      />
      <ReferenceInput
        filter={filter}
        label="Logo do Parceiro"
        source="logoId"
        reference="image"
        perPage={100}
      >
        <SelectImageInput onFilterChange={onChangeFilterImage} />
      </ReferenceInput>
    </FormTab>
  );
};

PartnerFormTab.propTypes = {
  editing: bool,
};

export default PartnerFormTab;
