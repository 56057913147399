import * as Yup from 'yup';
import { isPasswordConfirm } from '../schema-tests';
const requiredMsg = 'Preenchimento obrigatório.';
const confirmPasswordRequiredMsg = 'As senhas devem ser iguais.';
export const validateUserSmsCodeSchema = () => Yup.object({
  userId: Yup.string().required(requiredMsg),
  phoneNumberVerification: Yup.object({
    verificationId: Yup.string().required(requiredMsg),
    code: Yup.string().required(requiredMsg)
  })
});
export const resetUserPasswordSchema = () => Yup.object({
  userId: Yup.string().required(requiredMsg),
  validationId: Yup.string().required(requiredMsg),
  password: Yup.string().required(requiredMsg),
  confirmPassword: Yup.string().required(confirmPasswordRequiredMsg).test(isPasswordConfirm())
});
export const createDeviceWhitelistSchema = () => Yup.object({
  validationId: Yup.string().required(requiredMsg),
  username: Yup.string().required(requiredMsg),
  deviceId: Yup.string().required(requiredMsg),
  deviceType: Yup.string().required(requiredMsg)
});
export const verifyUserPhoneNumberSchema = () => Yup.object({
  userId: Yup.string().required(requiredMsg)
});
export const getAmpliUserLoginInfoSchema = () => Yup.object({
  username: Yup.string().required(requiredMsg)
});
export const getNotifyStudentByTypeSchema = () => Yup.object({
  userId: Yup.string().required(requiredMsg),
  notificationType: Yup.string().required(requiredMsg)
});