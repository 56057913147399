import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

export const CourseSEOShow = (props) => {
  return (
    <Show title="SEO de Curso - Show" {...props}>
      <SimpleShowLayout>
        <TextField label="Tag de Titulo" source="titleTag" />
        <ReferenceField
          label="Curso"
          source="courseId"
          reference="course"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="Imagem do curso" source="courseCardImageUrl" />
        <ArrayField label="Seções" source="sections">
          <SingleFieldList>
            <ChipField source="aggregatedName" />
          </SingleFieldList>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};
