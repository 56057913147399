import { some, map, DateTime, validateCpf, words, size, Interval } from '@ampli/utils';
import { GET_ADDRESS } from './locale';
export const isCreditCardPaymentMethod = paymentMethod => paymentMethod === 'CREDIT_CARD';
export const isBoletoPaymentMethod = paymentMethod => paymentMethod === 'BOLETO';
export const isValidCvvTest = () => ({
  name: 'isValidCvvTest',
  message: 'Cód. de segurança inválido.',
  test: value => {
    return /\d{3,4}/.test(value);
  }
});
export const isValidCreditCardTest = () => ({
  name: 'isValidCreditCardTest',
  message: 'Cartão de crédito inválido.',
  test: value => {
    const onlyNumbers = value && value.replace(/[^\d]+/g, '');
    return (onlyNumbers === null || onlyNumbers === void 0 ? void 0 : onlyNumbers.length) >= 16 && (onlyNumbers === null || onlyNumbers === void 0 ? void 0 : onlyNumbers.length) <= 17;
  }
});
export const isValidDocumentTest = () => ({
  name: 'isValidDocumentTest',
  message: 'CPF inválido.',
  test: value => {
    const onlyNumbers = value && value.replace(/[^\d]+/g, '');
    const filled = onlyNumbers && onlyNumbers.length === 11;
    return filled && validateCpf(onlyNumbers);
  }
});
export const isValidDateTest = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: 'isValidDateTest',
    message: 'Data inválida.',
    test: value => {
      const {
        format = 'dd/MM/yyyy'
      } = options;
      const filled = value && value.replace(/[^\d]+/g, '').length === 8;
      return filled && DateTime.fromFormat(value, format).isValid;
    }
  };
};
export const isAgeUnderSixteen = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    name: 'isAgeUnderSixteen',
    message: 'Inscrição para maiores de 16 anos',
    test: value => {
      const {
        format = 'dd/MM/yyyy'
      } = options;
      const birthDate = value;
      return !!birthDate && Interval.fromDateTimes(DateTime.fromFormat(birthDate, format), DateTime.local()).length('years') > 16;
    }
  };
};
export const isValidPostalCodeTest = _ref => {
  let {
    client
  } = _ref;
  return {
    name: 'isValidPostalCodeTest',
    message: 'CEP inválido.',
    test: async value => {
      const filled = value && value.replace(/[^\d]+/g, '').length === 8;
      const validPattern = filled && value.match(/^\d{5}-\d{3}$/);

      if (validPattern) {
        return await client.query({
          query: GET_ADDRESS,
          variables: {
            postalCode: value
          }
        }).then(_ref2 => {
          let {
            data,
            errors
          } = _ref2;
          return data && !errors;
        }).catch(() => false);
      }

      return false;
    }
  };
};
export const hasTextMinWordCountTest = _ref3 => {
  let {
    count
  } = _ref3;
  return {
    name: 'hasTextMinWordCountTest',
    message: `O texto deve ter no mínimo ${count} palavras.`,
    test: value => {
      const allWords = words(value);
      const wordsCount = size(allWords);
      return wordsCount >= count;
    }
  };
};
export const hasTextMaxWordCountTest = _ref4 => {
  let {
    count
  } = _ref4;
  return {
    name: 'hasTextMaxWordCountTest',
    message: `O texto deve ter no máximo ${count} palavras.`,
    test: value => {
      const allWords = words(value);
      const wordsCount = size(allWords);
      return wordsCount <= count;
    }
  };
};
export const isPasswordConfirm = () => ({
  name: 'isPasswordConfirm',
  message: 'As senhas estão diferentes.',
  test: function (confirmPassword) {
    const password = this.parent.password;

    if (password !== confirmPassword) {
      if (password.indexOf(confirmPassword) !== 0) {
        return false;
      } else {
        return this.createError({
          path: this.path,
          message: 'As senhas devem ser iguais.'
        });
      }
    }

    return true;
  }
});
export const isValidMimeType = function (mimeTypes) {
  let message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Tipo de arquivo não suportado.';
  return {
    name: 'isValidMimeType',
    message,
    test: function (file) {
      return some(map(mimeTypes, mimeTypeRegex => file && (mimeTypeRegex.test(file.type) || mimeTypeRegex.test(file.name))));
    }
  };
};
export const isValidExpirationDate = function () {
  let isFullYear = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    name: 'isValidExpirationDate',
    message: 'Data inválida.',
    test: function (value) {
      const dateFormat = isFullYear ? 'MM/yyyy' : 'MM/yy';
      const dateFormatLength = isFullYear ? 6 : 4;
      const filled = value && value.replace(/[^\d]+/g, '').length === dateFormatLength;

      if (filled) {
        const dateTime = DateTime.fromFormat(value, dateFormat);
        const currentMonth = DateTime.local().startOf('month');
        return dateTime.isValid && dateTime >= currentMonth;
      }

      return false;
    }
  };
};