import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
const _excluded = ["data", "loading"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useState } from 'react';
import { sortBy } from '@ampli/utils';
import { useQuery, useLazyQuery } from '../hooks';
import { FIND_STATES, FIND_CITIES, GET_ADDRESS, FIND_ALL_COUNTRIES } from './queries';
export const useFindStates = options => {
  const {
    loading,
    data
  } = useQuery(FIND_STATES, options);
  const [state, setState] = useState({});
  const states = loading || !data ? [] : sortBy(data.list, ['name']);
  return {
    states,
    state,
    setState,
    loading
  };
};
export const useFindCities = function (stateId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    loading,
    data
  } = useQuery(FIND_CITIES, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      stateId
    },
    skip: !stateId
  }));
  const [city, setCity] = useState({});
  const cities = loading || !data ? [] : sortBy(data.list, ['name']);
  return {
    cities,
    city,
    setCity,
    loading
  };
};
export const useGetAddress = function (postalCode) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery = useQuery(GET_ADDRESS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      postalCode
    },
    skip: !postalCode,
    fetchPolicy: 'no-cache'
  })),
        {
    data,
    loading
  } = _useQuery,
        rest = _objectWithoutProperties(_useQuery, _excluded);

  const address = loading || !data ? null : data.data;
  return _objectSpread({
    data: address,
    loading
  }, rest);
};
export const useGetAddressLazy = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useLazyQuery(GET_ADDRESS, options);
};
export const useFindAllCountries = () => {
  const {
    loading,
    data
  } = useQuery(FIND_ALL_COUNTRIES);
  const countries = loading || !data ? [] : data.list;
  return {
    countries,
    loading
  };
};