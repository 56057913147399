import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  UrlField,
  TopToolbar,
  EditButton,
  DeleteButton,
} from 'react-admin';
import { string, object } from '@ampli/utils';

const CandidateShowActions = ({ basePath, data, resource }) => {
  return (
    <TopToolbar>
      {data &&
        data.status !== 'LEAD' &&
        data.status !== 'ENROLLED' &&
        data.status !== 'PENDING_PAYMENT' && (
          <EditButton basePath={basePath} record={data} />
        )}
      {data &&
        data.status !== 'ENROLLED' &&
        data.status !== 'PENDING_PAYMENT' && (
          <DeleteButton
            disabled
            basePath={basePath}
            record={data}
            resource={resource}
          />
        )}
    </TopToolbar>
  );
};

CandidateShowActions.propTypes = {
  basePath: string,
  data: object,
  resource: object,
};

export const CandidateShow = (props) => (
  <Show title="Candidato" actions={<CandidateShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField label="Nome" source="fullName" />
      <ReferenceField
        label="Curso"
        source="courseId"
        reference="course"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Valor do Curso"
        source="coursePriceId"
        reference="coursePrice"
        link={false}
      >
        <TextField source="firstInstalmentPrice" />
      </ReferenceField>
      <TextField label="Nome" source="fullName" />
      <TextField label="E-mail" source="email" />
      <TextField label="Celular" source="mobilePhone" />
      <TextField label="Cidade Cadastrada" source="leadCity.name" />
      <TextField label="Estado Cadastrato" source="leadCity.state.acronym" />
      <TextField source="document" />
      <TextField source="birthDateParsed" />
      <BooleanField source="enemOption" />
      <TextField label="CEP" source="address.postalCode" />
      <TextField label="Logradouro" source="address.street" />
      <TextField label="Número" source="address.number" />
      <TextField label="Complemento" source="address.complement" />
      <TextField label="Bairro" source="address.neighbourhood" />
      <TextField label="Cidade" source="address.city.name" />
      <TextField label="Estado" source="address.state.acronym" />
      <ArrayField label="Necessidade Especiais" source="specialNeeds">
        <SingleFieldList>
          <ChipField label="Descrição" source="description" />
        </SingleFieldList>
      </ArrayField>
      <DateField
        label="Data de assinatura"
        source="audit.lastModifiedDate"
        locales="pt-BR"
      />
      <TextField label="Nota" source="grade" />
      <TextField label="Nota ENEM" source="enemGrade" />
      <UrlField label="Contrato" source="contractUrl" />
      <ReferenceField label="Vendedor" source="sellerId" reference="seller">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
