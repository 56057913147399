import React from 'react';
import {
  List,
  Datagrid,
  FunctionField,
  NumberField,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
} from 'react-admin';
import { format } from './common';
import { string, object, bool, number } from '@ampli/utils';
import { Filter } from '../../components';

export const CouponList = (props) => (
  <List
    title="Cupons"
    actions={<ListActions maxResults={10000} />}
    filters={<Filter />}
    empty={false}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField label="Código" source="code" />
      <TextField label="Descrição" source="description" />
      <FunctionField
        label="Desconto"
        render={(record) => format.discount(record)}
      />
      <NumberField label="Quantidate" source="initialAmount" />
      <TextField label="Data de Início" source="startDate" />
      <TextField label="Data de Expiração" source="expirationDate" />
      <TextField label="Tipo" source="type" />
      <TextField label="Categoria" source="category" />
    </Datagrid>
  </List>
);

const ListActions = ({
  currentSort,
  className,
  resource,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  basePath,
  maxResults,
  total,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={{ ...filterValues, ...permanentFilter }}
      exporter={exporter}
      maxResults={maxResults}
    />
  </TopToolbar>
);

ListActions.propTypes = {
  currentSort: object,
  className: string,
  resource: object,
  exporter: bool, // you can hide ExportButton if exporter = (null || false)
  filterValues: object,
  permanentFilter: object,
  basePath: string,
  maxResults: number,
  total: number,
};
