import { forEach } from 'lodash';
export const loadScript = function (src) {
  let attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (typeof document === 'undefined') return null;
  return new Promise((resolve, reject) => {
    var script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', resolve);
    script.addEventListener('error', reject);
    forEach(attributes, (value, key) => script.setAttribute(key, value));
    document.body.appendChild(script);
  });
};