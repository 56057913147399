import gql from 'graphql-tag';
import { GET_LIST, GET_ONE, UPDATE } from 'react-admin';
import { getList, getOne, updateOne } from '@ampli/admin-core';
import { omit } from '@ampli/utils';

const ENTITY = `
  id
  description
  desktopImageId
  mobileImageId
  location
`;

const locationValues = {
  HOME: 'Homepage',
};

const LIST_QUERY = gql`
    query ListBanners(
      $pagination: Pagination, $filter: Filter, $sort: Sort,
      ) {
        connection: listBanners(
          pagination: $pagination
          filter: $filter
          sort: $sort
          ) {
          data {
            ${ENTITY}
          }
          page {
          totalElements
        }
        }
    }
`;

const GET_ONE_QUERY = gql`
  query GetBanner($id: ID!) {
    data: getBanner(id: $id) {
      ${ENTITY}
    }
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateBanner($data: BannerUpdateInput!) {
    data: updateBanner(banner: $data) {
      ${ENTITY}
    }
  }
`;

const parseResponse = (data) => {
  const bannerLocation = locationValues[data.location];
  return {
    ...data,
    bannerLocation,
  };
};

const updateParseParams = ({ data }) => {
  return {
    data: {
      ...omit(data, [
        'imageDesktop',
        'imageMobile',
        'bannerLocation',
        'description',
        'location',
      ]),
    },
  };
};

export default {
  [GET_LIST]: getList(LIST_QUERY, parseResponse),
  [GET_ONE]: getOne(GET_ONE_QUERY, parseResponse),
  [UPDATE]: updateOne(UPDATE_ONE_QUERY, parseResponse, updateParseParams),
};
