import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';

export const LegalDocumentShow = (props) => {
  return (
    <Show title="Documentos Legais - Show" {...props}>
      <SimpleShowLayout>
        <TextField label="Nome" source="name" />
        <TextField label="Link" source="url" />
        <BooleanField label="Exibir" source="isEnabled" />
      </SimpleShowLayout>
    </Show>
  );
};
