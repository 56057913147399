import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["data", "loading"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useMutation, useQuery } from '../hooks';
import { APPEND_ON_TRACK, GET_TRACK } from './queries';
export const useAppendOnTrack = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return useMutation(APPEND_ON_TRACK, options);
};
export const useGetTrack = function (referenceId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery = useQuery(GET_TRACK, _objectSpread(_objectSpread({
    skip: !referenceId
  }, options), {}, {
    variables: {
      referenceId
    }
  })),
        {
    data,
    loading
  } = _useQuery,
        rest = _objectWithoutProperties(_useQuery, _excluded);

  return _objectSpread({
    data: loading || !data ? [] : data.data,
    loading
  }, rest);
};