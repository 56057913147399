import { DateTime } from 'luxon';
export const isValidDate = (value, options) => {
  const {
    format = 'dd/MM/yyyy',
    onlyFutureDates = false
  } = options || {};
  const date = DateTime.fromFormat(value, format);
  const filled = value && value.replace(/[^\d]+/g, '').length === 8;
  let validPattern = filled && date.isValid;

  if (validPattern && onlyFutureDates) {
    validPattern = DateTime.local() < date;
  }

  return validPattern;
};
export const isMSESupported = () => {
  return window && 'MediaSource' in window && 'SourceBuffer' in window && typeof window.MediaSource.isTypeSupported === 'function';
};
export const isValidCnpj = value => {
  if (!value) return 'Required';
  const isString = typeof value === 'string';
  const validTypes = isString || Number.isInteger(value) || Array.isArray(value);
  if (!validTypes) return 'Invalid type';

  if (isString) {
    if (value.length > 18) 'Invalid length';
    const digitsOnly = /^\d{14}$/.test(value);
    const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);
    if (digitsOnly || validFormat) true;else return 'Invalid format';
  }

  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];
  if (numbers.length !== 14) return 'Invalid length';
  const items = [...new Set(numbers)];
  if (items.length === 1) return 'Invalid sequence';

  const calc = x => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - sum % 11;
    return result > 9 ? 0 : result;
  };

  const digits = numbers.slice(12);
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return 'First invalid check digit';
  const digit1 = calc(13);
  if (digit1 === digits[1]) return '';
  return 'Second invalid check digit';
};
export const REGEX_FULL_NAME_VALIDATOR = /[a-zA-Z\u00C0-\u017F]{2,}\s([a-zA-Z\u00C0-\u017F]+)+/i;
export const REGEX_ONLY_NUMBERS_VALIDATOR = /^\d+$/;
export const REGEX_PHONE_NUMBER_VALIDATOR = /\(\d\d\)\s9\d{4}-\d{4}/;