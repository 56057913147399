import { useRef, useEffect } from 'react';
import { map, cond, constant, stubTrue, concat, get } from '@ampli/utils';

const useFocusOnError = function (formik) {
  let fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const submitCountRef = useRef(formik.submitCount);
  useEffect(() => {
    const firstInputErrorRef = cond(concat(map(fields, (fieldRef, fieldName) => [constant(get(formik.errors, fieldName) && get(formik.touched, fieldName)), constant(fieldRef)]), [[stubTrue, constant({})]]))();

    if (firstInputErrorRef.current && formik.submitCount !== submitCountRef.current) {
      firstInputErrorRef.current.focus();
    }

    submitCountRef.current = formik.submitCount;
  }, [formik.touched, formik.error, formik.submitCount]);
};

export default useFocusOnError;