import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

export const SellerCategoryShow = (props) => (
  <Show title="Categoria de Vendedores - Detalhe" {...props}>
    <SimpleShowLayout>
      <TextField label="Nome" source="name" />
      <TextField label="UTM Source" source="utmSource" />
      <TextField label="UTM Campaign" source="utmCampaign" />
      <TextField label="UTM Medium" source="utmMedium" />
      <TextField label="Prefixo do Cupom" source="couponPrefix" />
    </SimpleShowLayout>
  </Show>
);
