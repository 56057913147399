import { DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE } from 'react-admin';
import baseCandidate from './base-candidate';

export default {
  [GET_LIST]: baseCandidate.candidateList,
  [GET_ONE]: baseCandidate.candidateGetOne,
  [GET_MANY]: baseCandidate.candidateGetMany,
  [UPDATE]: baseCandidate.candidateUpdate,
  [DELETE]: baseCandidate.candidateDelete,
};
