import { ReferenceInput, SelectInput, useGetList } from 'react-admin';
import { css, cx } from '@ampli/ui';
import React, { useEffect, useState } from 'react';
import { values, bool, string } from '@ampli/utils';
import { useForm } from 'react-final-form';

const StateReferenceInput = (props) => (
  <ReferenceInput
    className={cx(
      'state-reference-input',
      css`
        width: 100%;
      `
    )}
    {...props}
  />
);

const CitySelectInput = (props) => (
  <SelectInput
    {...props}
    className={cx(
      'state-reference-input',
      css`
        width: 100%;
      `
    )}
  />
);

const CityForm = ({ formValueStateId, citySource, allowEmpty }) => {
  const [cityList, setCityList] = useState([]);
  const { data, loading } = useGetList(
    'city',
    { page: 1, perPage: 500 },
    { field: 'name', order: 'ASC' },
    { stateId: formValueStateId }
  );
  useEffect(() => {
    if (!loading) {
      setCityList(values(data));
    }
    // eslint-disable-next-line
  }, [formValueStateId, loading]);
  return (
    !loading &&
    formValueStateId && (
      <CitySelectInput
        label="Cidade"
        choices={cityList}
        source={citySource}
        allowEmpty={allowEmpty}
      />
    )
  );
};

CityForm.propTypes = {
  formValueStateId: string,
  citySource: string,
  allowEmpty: bool,
};

export const CityAndStateForm = ({
  formValueStateId,
  className,
  stateSource,
  citySource,
  allowEmpty,
}) => {
  const form = useForm();
  return (
    <div className={className}>
      <StateReferenceInput
        label="Estado"
        source={stateSource}
        reference="state"
        onChange={() => form.change(citySource, null)}
        allowEmpty={allowEmpty}
      >
        <SelectInput source="acronym" />
      </StateReferenceInput>
      {formValueStateId && (
        <CityForm
          formValueStateId={formValueStateId}
          citySource={citySource}
          allowEmpty={allowEmpty}
        />
      )}
    </div>
  );
};

CityAndStateForm.propTypes = {
  formValueStateId: string,
  className: string,
  stateSource: string,
  citySource: string,
  allowEmpty: bool,
};
