import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
const _excluded = ["loading", "data"],
      _excluded2 = ["loading", "data"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useMutation, useQuery } from '../hooks';
import { CREATE_SELLER_PUBLIC, GET_SELLER, GET_SELLER_BY_CPF } from './queries';
import { createSellerPublicSchema } from './schema';
export const useGetSeller = function (sellerId) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery = useQuery(GET_SELLER, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      id: sellerId
    },
    skip: !sellerId
  })),
        {
    loading,
    data
  } = _useQuery,
        rest = _objectWithoutProperties(_useQuery, _excluded);

  const exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading
  }, rest);
};
export const useGetSellerByCpf = function (cpf) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  const _useQuery2 = useQuery(GET_SELLER_BY_CPF, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      cpf
    },
    skip: !cpf
  })),
        {
    loading,
    data
  } = _useQuery2,
        rest = _objectWithoutProperties(_useQuery2, _excluded2);

  const exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading
  }, rest);
};
export const useCreateSellerPublic = options => {
  return [...useMutation(CREATE_SELLER_PUBLIC, options), createSellerPublicSchema];
};