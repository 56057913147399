import gql from 'graphql-tag';
import { GET_LIST, GET_ONE, GET_MANY, UPDATE } from 'react-admin';
import { getList, getMany, getOne, updateOne } from '@ampli/admin-core';

const ENTITY = `
  id
  courseId
  firstInstalmentPrice
  otherInstalmentsPrice
`;

const LIST_QUERY = gql`
    query DefaultCoursePriceList(
      $pagination: Pagination,
      $filter: Filter
      ) {
        connection: listDefaultCoursePrice(
          pagination: $pagination,
          filter: $filter
          ) {
          data {
            ${ENTITY}
          }
          page {
            number
            size
            totalPages
            totalElements
          }
        }
    }
`;

const GET_ONE_QUERY = gql`
  query GetDefaultCoursePrice($id: ID!) {
    data: getDefaultCoursePrice(id: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetDefaultCoursePrice($ids: [ID]!) {
    data: getManyDefaultCoursePrice (
      coursePriceIds: $ids
    ) {
      ${ENTITY}
    }
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateDefaultCoursePrice($id: ID!, $data: DefaultCoursePriceInput) {
    data: updateDefaultCoursePrice(id: $id, defaultCoursePrice: $data) {
      ${ENTITY}
    }
  }
`;

const updateParseObject = (result) => result;

const updateParseParams = (params) => {
  delete params.data.id;
  return params;
};

export default {
  [GET_LIST]: getList(LIST_QUERY),
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
  [UPDATE]: updateOne(UPDATE_ONE_QUERY, updateParseObject, updateParseParams),
};
