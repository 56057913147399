import React from 'react';
import { ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const SellerUnitShow = (props) => (
  <Show title="Unidade de Vendedores - Detalhe" {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField label="Nome" source="name" />
      <TextField label="Descrição" source="description" />
      <ReferenceField
        label="Regional"
        source="sellerRegionalId"
        reference="sellerRegional"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Coordenador" source="managerName" />
    </SimpleShowLayout>
  </Show>
);
