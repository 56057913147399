import { useFormik } from '@ampli/utils';
import { useResetUserPassword } from '@ampli/services';

const useResetPasswordForm = _ref => {
  let {
    schemaOptions = {},
    onSubmit,
    onError,
    onCompleted
  } = _ref;

  const handleError = error => {
    const errorMsg = /Validation id .+ is invalid/gi.test((error === null || error === void 0 ? void 0 : error.message) || '') ? 'Ocorreu um problema ao verificar seu telefone. ' + 'Volte ao passo anterior e refaça a verificação.' : 'Ocorreu um problema ao tentar criar uma senha. Tente novamente.';
    onError === null || onError === void 0 || onError(errorMsg);
  };

  const [mutate, {
    data,
    called,
    loading,
    error,
    schema
  }] = useResetUserPassword({
    onError: handleError,
    onCompleted: response => {
      if (!response.errors && response.data) {
        onCompleted === null || onCompleted === void 0 || onCompleted(response.data);
      } else {
        handleError();
      }
    }
  });

  const handleSubmit = values => {
    const userId = values.userId;
    const validationId = values.validationId;
    const password = values.password;
    mutate({
      variables: {
        userId,
        validationId,
        password
      }
    });
    onSubmit === null || onSubmit === void 0 || onSubmit();
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      userId: '',
      validationId: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: schema(schemaOptions),
    onSubmit: handleSubmit
  });
  return {
    formik,
    loading,
    called,
    error,
    data
  };
};

export default useResetPasswordForm;