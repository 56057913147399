import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  BooleanInput,
  SelectInput,
} from 'react-admin';

export const SectionEdit = (props) => {
  return (
    <Edit title="Section - Edit" undoable={false} {...props}>
      <SimpleForm redirect="list">
        <TextInput label="Nome" source="name" validate={required()} />
        <ReferenceInput
          label="Categoria"
          source="categoryId"
          reference="category"
          sort={{ field: 'name', order: 'ASC' }}
          validate={required()}
        >
          <SelectInput />
        </ReferenceInput>
        <BooleanInput
          label="Visível na vitrine"
          source="visible"
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};
