import React from 'react';
import { Filter as RaFilter, SearchInput } from 'react-admin';

const Filter = (props) => (
  <RaFilter {...props}>
    <SearchInput source="q" alwaysOn />
  </RaFilter>
);

export default Filter;
