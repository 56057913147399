import {
  GET_LIST,
  GET_ONE,
  UPDATE,
  DELETE,
  GET_MANY,
  CREATE,
} from 'react-admin';
import gql from 'graphql-tag';
import {
  createOne,
  deleteOne,
  getList,
  getMany,
  getOne,
  updateOne,
} from '@ampli/admin-core';

const ENTITY = `
  id
  name
  ownerName
  ownerPhone
  status
  address {
    street
    number
    postalCode
    complement
    neighbourhood
    cityId
    stateId
  }
  courseDegree
  mecCode
  collaborateId
`;

const LIST_QUERY = gql`
    query ListAffiliates($pagination: Pagination, $filter: Filter, $sort: Sort) {
        connection: listAffiliates(
            pagination: $pagination
            filter: $filter
            sort: $sort
        ) {
            data {
              ${ENTITY}
            }
            page {
                totalElements
            }
        }
    }
`;

const GET_ONE_QUERY = gql`
  query GetAffiliate($id: ID!) {
    data: getAffiliate(affiliateId: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetAffiliates($ids: [ID!]!) {
    data: getAffiliates (
      affiliateIds: $ids
    ) {
      ${ENTITY}
    }
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateAffiliate($data: UpdateAffiliateInput!) {
    data: updateAffiliate(affiliate: $data) {
      ${ENTITY}
    }
  }
`;

const DELETE_ONE_QUERY = gql`
  mutation DeleteAffiliate($id: ID!) {
    data: deleteAffiliate(affiliateId: $id)
  }
`;

const CREATE_ONE_QUERY = gql`
  mutation CreateAffiliate($data: CreateAffiliateInput!) {
    data: createAffiliate(affiliate: $data) {
      ${ENTITY}
    }
  }
`;

export default {
  [GET_LIST]: getList(LIST_QUERY),
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
  [CREATE]: createOne(CREATE_ONE_QUERY),
  [UPDATE]: updateOne(UPDATE_ONE_QUERY),
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
};
