export const courseDegrees = [
  {
    id: 'GRADUATE',
    name: 'Pós Graduação',
  },
  {
    id: 'UNDERGRADUATE',
    name: 'Graduação',
  },
  {
    id: 'EJA',
    name: 'EJA',
  },
  {
    id: 'TECHNICAL',
    name: 'Técnico',
  },
];
