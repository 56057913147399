import { css } from '@emotion/css';
import { memoize, isPlainObject, isArray, map, fromPairs, camelCase } from '@ampli/utils';
import { px2rem, getSpaceSize, getFontSize, getLineHeightSize, svgComponentToDataUri } from "./functions";
export const boxShadow = memoize(type => {
  switch (type) {
    case 'card':
      {
        return css`
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.14);
      `;
      }

    case 'reverse-card':
      {
        return css`
        box-shadow: 0px -4px 7px rgba(0, 0, 0, 0.14);
      `;
      }

    case 'highlight':
      {
        return css`
        box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.14);
      `;
      }

    default:
      {
        return css`
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
      `;
      }
  }
});
export const resolveCssProperty = (property, value) => {
  let resolved;

  if (isPlainObject(value)) {
    resolved = fromPairs(map(value, (propertyValue, propertyKey) => {
      return [camelCase(`${property}-${propertyKey}`), getSpaceSize(propertyValue)];
    }));
  } else if (isArray(value)) {
    resolved = {
      [property]: map(value, propertyValue => getSpaceSize(propertyValue)).join(' ')
    };
  } else {
    resolved = {
      [property]: getSpaceSize(value)
    };
  }

  return css(resolved);
};
export const iconBackground = (Icon, options) => {
  const {
    size = '16px',
    color = 'text-light'
  } = options || {};
  const resolvedSize = px2rem(size);
  return css`
    /* prettier-ignore */
    background-image: url("${svgComponentToDataUri(Icon, {
    currentColor: color
  })}");
    background-repeat: no-repeat;
    background-position: center center;
    height: ${resolvedSize};
    width: ${resolvedSize};
  `;
};
export const centralized = options => {
  const {
    size
  } = options || {};
  const cssSize = size && `calc(-${size} / 2)`;
  return css`
    position: absolute;
    top: 50%;
    left: 50%;

    ${cssSize ? css`
          margin-top: ${cssSize};
          margin-left: ${cssSize};
        ` : css`
          transform: translate(-50%, -50%);
        `}
  `;
};
export const hideText = memoize(() => {
  return css`
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  `;
});
export const fontSize = memoize(function () {
  let size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'medium';
  return css`
    font-size: ${getFontSize(size)};
    line-height: ${getLineHeightSize(size)};
  `;
});
export const ellipsis = memoize(size => {
  return css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${size};
  `;
});
export const paddingSize = function () {
  let size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'small';
  let orientation = arguments.length > 1 ? arguments[1] : undefined;
  return css`
    padding${orientation ? `-${orientation}` : ''}: ${getSpaceSize(size)};
  `;
};
export const marginSize = function () {
  let size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'small';
  let orientation = arguments.length > 1 ? arguments[1] : undefined;
  return css`
    margin${orientation ? `-${orientation}` : ''}: ${getSpaceSize(size)};
  `;
};