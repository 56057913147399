import { DateTime } from '@ampli/utils';
import React from 'react';
import {
  Edit,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  SimpleForm,
  DateInput,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  CheckboxGroupInput,
  BooleanInput,
} from 'react-admin';
import { validation, data } from './common';

const canEditField = (record) =>
  DateTime.fromISO(record.startDate).startOf('hour') >=
  DateTime.local().startOf('hour');

export const CouponEdit = (props) => (
  <Edit title="Cupon - Edit" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <FormDataConsumer>
        {({ record }) =>
          canEditField(record) && (
            <TextInput
              label="Código"
              source="code"
              validate={validation.code}
            />
          )
        }
      </FormDataConsumer>
      <TextInput
        label="Descrição"
        source="description"
        validate={validation.description}
      />
      <FormDataConsumer>
        {({ record }) =>
          canEditField(record) && (
            <SelectInput
              label="Tipo"
              source="type"
              choices={data.types}
              validate={validation.type}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ record }) =>
          canEditField(record) && (
            <NumberInput
              label="Desconto"
              source="discount"
              validate={validation.discount}
            />
          )
        }
      </FormDataConsumer>
      <NumberInput
        label="Quantidate de cupons disponíveis"
        source="initialAmount"
        validate={validation.initialAmount}
      />
      <FormDataConsumer>
        {({ record }) =>
          canEditField(record) && (
            <DateInput
              label="Data de Início"
              source="startDate"
              validate={validation.startDate}
            />
          )
        }
      </FormDataConsumer>
      <DateInput
        label="Data de Expiração"
        source="expirationDate"
        validate={validation.expirationDate}
      />

      <FormDataConsumer>
        {({ record }) =>
          canEditField(record) && (
            <NumberInput
              label="Quantidade de parcelas"
              source="numberOfInstalments"
              validate={validation.numberOfInstalments}
              defaultValue={1}
            />
          )
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ record }) =>
          canEditField(record) && (
            <SelectInput
              label="Categoria"
              source="category"
              choices={data.categories}
            />
          )
        }
      </FormDataConsumer>
      <ReferenceArrayInput source="courseIds" reference="course">
        <AutocompleteArrayInput source="name" optionText="name" />
      </ReferenceArrayInput>
      <CheckboxGroupInput source="courseDegrees" choices={data.courseDegree} />
      <BooleanInput
        label="Somente para novos ingressos?"
        source="onlyNewEnrollment"
      />
    </SimpleForm>
  </Edit>
);
