import React from 'react';
import { TextInput } from 'react-admin';
import { PhoneInput } from '@ampli/ui';
import { object } from '@ampli/utils';

export const AdminPhoneInput = (props) => (
  <TextInput
    label="Telefone"
    source={props.source}
    InputProps={{ inputComponent: PhoneInput, inputProps: {} }}
  />
);

AdminPhoneInput.propTypes = {
  source: object,
};
