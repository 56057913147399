import React from 'react';
import { Edit, SimpleForm, TextInput, email } from 'react-admin';
import { AdminPhoneInput } from '../../components';

const validateEmail = email();

export const LeadEdit = (props) => (
  <Edit title="Lead - Edit" undoable={false} {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Email" source="email" validate={validateEmail} />
      <AdminPhoneInput {...props} inputRef="" label="Celular" source="phone" />
    </SimpleForm>
  </Edit>
);
