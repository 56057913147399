import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';

export const SectionShow = (props) => {
  return (
    <Show title="Section - Show" {...props}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <TextField label="Nome" source="name" />
        <BooleanField label="Categoria" source="categoryId" />
        <TextField label="Visível na vitrine" source="visible" />
      </SimpleShowLayout>
    </Show>
  );
};
