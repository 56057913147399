import React from 'react';
import {
  Create,
  DateInput,
  email,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { AdminCpfInput, AdminPhoneInput } from '../../components';

const validateEmail = email();

export const SellerCreate = (props) => (
  <Create title="Vendedor - Create" {...props}>
    <SimpleForm redirect="show">
      <TextInput label="Nome" source="name" />
      <TextInput
        label="Email"
        source="email"
        validate={validateEmail}
        type="email"
      />
      <DateInput label="Data de Nascimento" source="birthDate" />
      <AdminPhoneInput source="phone" />
      <AdminCpfInput source="cpf" />
      <ReferenceInput
        label="Unidade"
        source="sellerUnitId"
        reference="sellerUnit"
        allowEmpty
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Categoria"
        source="sellerCategoryId"
        reference="sellerCategory"
        perPage={1000}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
