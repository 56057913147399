import localForage from 'localforage';
import { getGlobal } from "./global";
const storage = 'hipotenusa';
export let authStorage;
export let dataStorage;
export const localStorage = typeof window !== 'undefined' ? getGlobal().localStorage || {} : null;
export const initializeStorages = function () {
  let storeNamePrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'generic';
  authStorage = localForage.createInstance({
    name: storage,
    storeName: `${storeNamePrefix}-auth`
  });
  dataStorage = localForage.createInstance({
    name: storage,
    storeName: `${storeNamePrefix}-data`
  });
};