var _getGlobal$navigator, _permissions$query;

import { useEffect, useState } from 'react';
import { getGlobal } from '@ampli/utils';
const permissions = (_getGlobal$navigator = getGlobal().navigator) === null || _getGlobal$navigator === void 0 ? void 0 : _getGlobal$navigator.permissions;
const permissionsQuery = permissions === null || permissions === void 0 || (_permissions$query = permissions.query) === null || _permissions$query === void 0 ? void 0 : _permissions$query.bind(permissions);

const useNavigatorPermissions = query => {
  const [hasPermission, setHasPermission] = useState();
  const [error, setError] = useState();
  useEffect(() => {
    if (permissionsQuery) {
      permissionsQuery(query).then(permission => {
        if (permission.state === 'granted') {
          setHasPermission(true);
        } else {
          setHasPermission(false);
        }

        return query;
      }).catch(setError);
    }
  }, [query]);
  return [hasPermission, error];
};

export default useNavigatorPermissions;