import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

export const SellerRegionalShow = (props) => (
  <Show title="Regional de Vendedores - Detalhe" {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField label="Nome" source="name" />
      <TextField label="Descrição" source="description" />
    </SimpleShowLayout>
  </Show>
);
