import { DateTime, Interval } from 'luxon';
export const legalAgeComparator = function (birthDate) {
  let settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    dateFormat = 'dd/MM/yyyy',
    legalAge = 18
  } = settings;
  return !!birthDate && Interval.fromDateTimes(DateTime.fromFormat(birthDate, dateFormat), DateTime.local()).length('years') >= legalAge;
};
export const includesValues = (origin, contain) => {
  return origin.some(r => contain.indexOf(r) >= 0);
};