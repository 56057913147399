import React from 'react';
import { List, Datagrid, TextField, BooleanField } from 'react-admin';

export const LegalDocumentList = (props) => (
  <List title="Documentos Legais - List" {...props} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField label="Nome" source="name" />
      <TextField label="Link" source="url" />
      <BooleanField label="Exibir" source="isEnabled" />
    </Datagrid>
  </List>
);
