import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  FunctionField,
  ReferenceField,
} from 'react-admin';

export const SectionList = (props) => (
  <List title="Section - List" bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <TextField label="Nome" source="name" />
      <ReferenceField
        label="Categoria"
        source="categoryId"
        reference="category"
      >
        <FunctionField render={(record) => record && `${record.name}`} />
      </ReferenceField>
      <BooleanField label="Visível na vitrine" source="visible" />
    </Datagrid>
  </List>
);
