import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';

export const CourseCategoryShow = (props) => {
  return (
    <Show title="Mostrar Categorias de Curso" {...props}>
      <SimpleShowLayout>
        <TextField label="Nome da Categoria" source="name" />
        <BooleanField label="Exibir" source="visible" />
        <BooleanField label="Categoria Principal" source="main" />
        <TextField label="Tipo de curso" source="formattedCourseDegree" />
        <TextField label="Icone" source="iconLink" />
      </SimpleShowLayout>
    </Show>
  );
};
