import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
const defaultMessages = {
  en: englishMessages
};
const defaultSettings = {
  messages: {},
  defaultLocale: 'en',
  settings: {}
};
export const i18nProviderCreator = function () {
  let settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultSettings;
  const {
    messages = {},
    defaultLocale = 'en',
    polyglotI18nSettings = {}
  } = settings;

  const finalMessages = _objectSpread(_objectSpread({}, defaultMessages), messages);

  return polyglotI18nProvider(locale => finalMessages[locale], defaultLocale, _objectSpread({
    allowMissing: true
  }, polyglotI18nSettings));
};