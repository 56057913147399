import React from 'react';
import { Edit, TabbedForm } from 'react-admin';

import PartnerFormTab from '../../components/partner-form-tab';

export const PartnerEdit = (props) => (
  <Edit title="Disciplina" {...props} undoable={false}>
    <TabbedForm redirect={false}>
      <PartnerFormTab editing />
    </TabbedForm>
  </Edit>
);
