import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { inspect } from 'util';
import * as Sentry from '@sentry/browser';
const levels = {
  error: 0,
  warn: 1,
  info: 2,
  verbose: 3,
  debug: 4,
  silly: 5
};
const termColors = {
  red: '31',
  green: '32',
  olive: '33',
  blue: '34'
};
const originalConsole = {
  log: console.log
};
Object.keys(levels).forEach(level => {
  if (console[level]) {
    originalConsole[level] = console[level];
  }
});

const createLogger = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let {
    appName,
    logLevel = 'info',
    debugMode = false,
    isNode = true,
    inspectOptions = {},
    sentrySettings = null
  } = options;

  if (sentrySettings) {
    Sentry.init(_objectSpread(_objectSpread({}, sentrySettings), {}, {
      ignoreErrors: [/^Warning: /, 'UNAUTHENTICATED', 'Authentication failed', /^Network error: /, /^BAD_USER_INPUT: /, ...(sentrySettings.ignoreErrors || [])],
      allowUrls: [/^https:\/\/(.+\.)?ampli\.com\.br/i, ...(sentrySettings.allowUrls || [])]
    }));
  }

  const color = (color, str) => {
    if (!debugMode) return [str];

    if (isNode) {
      return [`\x1b[${termColors[color]}m${str}\x1b[0m`];
    }

    return [`%c${str}`, `color: ${color};`];
  };

  const colors = {};
  Object.keys(termColors).forEach(colorName => {
    colors[colorName] = str => color(colorName, str);
  });
  const levelColor = {
    error: colors.red,
    warn: colors.olive,
    info: colors.blue,
    debug: colors.green,
    silly: function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return args;
    }
  };
  inspectOptions = _objectSpread({
    colors: debugMode
  }, inspectOptions);
  logLevel = logLevel.toLowerCase();

  const prepareArgs = function (level) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }

    args = args.map(a => a === Object(a) && isNode ? debugMode ? inspect(a, inspectOptions) : JSON.stringify(a) : a);
    args.unshift(level);
    !debugMode && args.unshift(new Date().toISOString().replace(/[TZ]/g, ' '));
    return args;
  };

  const log = function (logFunc) {
    const log = originalConsole[logFunc] || originalConsole.log;

    for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
      args[_key3 - 1] = arguments[_key3];
    }

    log && log(...prepareArgs(...args));

    if (logFunc === 'error' && sentrySettings) {
      const errorIndex = debugMode && !isNode ? 2 : 1;
      let error = args[errorIndex] || 'UNKNOWN ERROR';

      if (!(error instanceof Error)) {
        error = new Error(error.message || error);
      }

      Sentry.withScope(scope => {
        if (error.info) {
          scope.setExtras(error.info);
        }

        scope.setTag('app.name', appName);
        Sentry.captureException(error);
      });
    }
  };

  const logger = {};
  Object.keys(levels).forEach(level => {
    logger[level] = function () {
      if (levels[logLevel] >= levels[level]) {
        for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
          args[_key4] = arguments[_key4];
        }

        log(level, ...levelColor[level](level.toUpperCase()), ...args);
      }
    };
  });
  return logger;
};

const hijackConsole = function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const logger = createLogger(options);
  Object.keys(levels).forEach(level => {
    console[level] = logger[level];
  });
  return console;
};

export { Sentry, hijackConsole };
export default createLogger;