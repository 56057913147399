import gql from 'graphql-tag';
import { DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE } from 'react-admin';
import {
  deleteOne,
  getList,
  getMany,
  getOne,
  updateOne,
} from '@ampli/admin-core';

const ENTITY = `
  id
  email
  phone
`;

const LIST_QUERY = gql`
  query ListLeadLight($pagination: Pagination, $sort: Sort) {
    connection: listLeadLight(pagination: $pagination, sort: $sort) {
      data {
        ${ENTITY}
      }
      page {
        number
        size
        totalElements
        totalPages
      }
    }
  }
`;

const GET_ONE_QUERY = gql`
  query GetLead($id: ID) {
    data: getLead(id: $id) {
      ${ENTITY}
    }
  }
`;

const GET_MANY_QUERY = gql`
  query GetManyLeads($ids: [ID!]!) {
    data: getManyLeads (
      ids: $ids
    ) {
      ${ENTITY}
    }
  }
`;

const DELETE_ONE_QUERY = gql`
  mutation DeleteLead($id: ID!) {
    data: deleteLead(id: $id)
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateLead($id: ID, $data:LeadData) {
    data: updateLead(id: $id, lead: $data) {
      ${ENTITY}
    }
  }
`;

const updateParseObject = (result) => result;

const updateParseParams = (params) => {
  delete params.data.id;

  return params;
};

export default {
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
  [GET_LIST]: getList(LIST_QUERY),
  [GET_MANY]: getMany(GET_MANY_QUERY),
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [UPDATE]: updateOne(UPDATE_ONE_QUERY, updateParseObject, updateParseParams),
};
