import React from 'react';
import { Create, SimpleForm, TextInput, email } from 'react-admin';
import { AdminPhoneInput } from '../../components';

const validateEmail = email();

export const LeadCreate = (props) => (
  <Create title="Lead - Create" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Email" source="email" validate={validateEmail} />
      <AdminPhoneInput {...props} inputRef="" label="Celular" source="phone" />
    </SimpleForm>
  </Create>
);
