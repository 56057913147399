import gql from 'graphql-tag';
import { CREATE, DELETE, GET_LIST, UPDATE, GET_ONE } from 'react-admin';
import {
  createOne,
  deleteOne,
  getList,
  updateOne,
  getOne,
} from '@ampli/admin-core';
import { omit } from '@ampli/utils';
import { kebabCase } from 'lodash';

const ENTITY = `
    id
    name
    imageId
    tags
    firstDescription
    secondDescription
    thirdDescription
    cta
    urlWebPage
    courseIds
    courseDegrees
    displayedInCourses
    displayedIn
    sequence
`;
const internalURL = 'https://ava.ampli.com.br/produtos/';

const translatedWords = {
  MY_COURSES: 'Meus Cursos',
  COURSE: 'Cursos',
  UNDERGRADUATE: 'Graduação',
  GRADUATE: 'Pós Graduação',
  EJA: 'Eja',
  TECHNICAL: 'Técnico',
};

const CREATE_ONE_QUERY = gql`
    mutation CreateProductOffer($data: CreateProductOfferInput!) {
        data: createProductOffer(productOffer: $data){
            ${ENTITY}
            urlCode
        }
    }
`;
const DELETE_ONE_QUERY = gql`
  mutation DeleteProductOffer($id: ID!) {
    data: deleteProductOffer(productOfferId: $id)
  }
`;

const LIST_QUERY = gql`
    query ListProductOffers($pagination: Pagination, $filter: JSON) {
        connection: listProductOffers(pagination: $pagination, filter: $filter, sort: {field: "sequence", order: ASC}) {
            data {
                ${ENTITY}
            }
            page {
                number
                size
                totalPages
                totalElements
            }
        }
    }
`;

const GET_ONE_QUERY = gql`
  query GetProductOffer($id: ID!) {
    data: getProductOffer(productOfferId: $id) {
      ${ENTITY}
      image {
        baseUrl
        formats
      }
    }
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateProductOffer( $data: UpdateProductOfferInput!) {
    data: updateProductOffer(productOffer: $data) {
      ${ENTITY}
      urlCode
    }
  }
`;

const validateSelectedShowIn = (displayedInCourses, courseDegrees) => {
  const hasDisplayedInCourses = displayedInCourses.length > 0;
  const hasCourseDegrees = courseDegrees.length > 0;

  if (hasDisplayedInCourses && hasCourseDegrees) return 'BOTH_CHOICES';
  if (hasDisplayedInCourses) return 'COURSE';
  if (hasCourseDegrees) return 'TYPE_OF_COURSE';

  return 'ALL_COURSES';
};

const parseResponse = (data) => {
  const displayedInTranslated = translatedWords[data.displayedIn];
  const formattedCourseDegree = data.courseDegrees
    .map((course) => translatedWords[course])
    .join(', ');
  if (!data?.image)
    return {
      ...data,
      formattedCourseDegree,
      displayedInTranslated,
      useIn: validateSelectedShowIn(
        data.displayedInCourses,
        data.courseDegrees
      ),
    };

  const thumbnail = `${data.image.baseUrl}/thumbnail`;
  return {
    ...data,
    thumbnail,
    formattedCourseDegree,
    displayedInTranslated,
    useIn: validateSelectedShowIn(data.displayedInCourses, data.courseDegrees),
  };
};

const validateURL = (data) => {
  const urlCode = kebabCase(data.name);
  let urlWebPage = data.urlWebPage;
  if (urlWebPage.indexOf(internalURL) !== -1) {
    const params =
      urlWebPage.indexOf('?') !== -1 ? '?' + urlWebPage.split('?')[1] : '';
    urlWebPage = internalURL + urlCode + params;
  }

  return { urlCode, urlWebPage };
};

const validateNewsCourseData = (data) => {
  const isCoursePage = data.displayedIn === 'COURSE';
  const courseDegrees =
    !(
      isCoursePage &&
      (data.useIn === 'BOTH_CHOICES' || data.useIn === 'TYPE_OF_COURSE')
    ) && 'courseDegrees';
  const displayedInCourses =
    !(
      isCoursePage &&
      (data.useIn === 'BOTH_CHOICES' || data.useIn === 'COURSE')
    ) && 'displayedInCourses';
  return { courseDegrees, displayedInCourses };
};

const parseRequestParams = ({ data }) => {
  const { urlWebPage, urlCode } = validateURL(data);
  const { courseDegrees, displayedInCourses } = validateNewsCourseData(data);
  return {
    data: {
      ...omit(data, [
        'image',
        'isExternalUrl',
        'useIn',
        courseDegrees,
        displayedInCourses,
      ]),
      urlCode,
      urlWebPage,
    },
  };
};

const updateParseParams = ({ data }) => {
  const { urlWebPage, urlCode } = validateURL(data);
  const { courseDegrees, displayedInCourses } = validateNewsCourseData(data);
  return {
    data: {
      ...omit(data, [
        'image',
        'previousData',
        'thumbnail',
        'useIn',
        'formattedCourseDegree',
        'displayedInTranslated',
        courseDegrees,
        displayedInCourses,
      ]),
      urlCode,
      urlWebPage,
    },
  };
};

export default {
  [CREATE]: createOne(CREATE_ONE_QUERY, parseResponse, parseRequestParams),
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
  [GET_LIST]: getList(LIST_QUERY, parseResponse),
  [GET_ONE]: getOne(GET_ONE_QUERY, parseResponse),
  [UPDATE]: updateOne(UPDATE_ONE_QUERY, parseResponse, updateParseParams),
};
