import gql from 'graphql-tag';
import { GET_LIST, GET_ONE, UPDATE, CREATE, DELETE } from 'react-admin';
import {
  getList,
  getOne,
  updateOne,
  createOne,
  deleteOne,
} from '@ampli/admin-core';

const ENTITY = `
    id
    name
    url
    isEnabled
`;

const LIST_QUERY = gql`
    query LegalDocumentsList(
      $pagination: Pagination,
      $filter: Filter
      ) {
        connection: listLegalDocuments(
          pagination: $pagination,
          filter: $filter
          ) {
          data {
            ${ENTITY}
          }
          page {
            number
            size
            totalPages
            totalElements
          }
        }
    }
`;

const GET_ONE_QUERY = gql`
  query GetLegalDocument($id: ID!) {
    data: getLegalDocument(id: $id) {
      ${ENTITY}
    }
  }
`;

const UPDATE_ONE_QUERY = gql`
  mutation UpdateLegalDocumentWithFile($data: LegalDocumentUpdateWithFileInput!) {
    data: updateLegalDocumentWithFile(legalDocument: $data) {
      ${ENTITY}
    }
  }
`;

const CREATE_ONE_QUERY = gql`
    mutation CreateLegalDocumentWithFile($data: LegalDocumentCreateWithFileInput!) {
        data: createLegalDocumentWithFile(legalDocument: $data){
            ${ENTITY}
        }
    }
`;

const DELETE_ONE_QUERY = gql`
  mutation DeleteLegalDocument($id: ID!) {
    data: deleteLegalDocument(id: $id)
  }
`;

const parseRequestParams = ({ data = {} }) => {
  const file = (data.file || {}).rawFile;
  return {
    data: {
      file,
      name: data.name,
      url: data.url,
      isEnabled: data.isEnabled === null ? false : data.isEnabled,
    },
  };
};

const parseResponse = (data) => {
  return {
    ...data,
  };
};

const updateParseParams = ({ data }) => {
  const file = (data.file || {}).rawFile;
  return {
    data: {
      id: data.id,
      file,
      name: data.name,
      url: data.url,
      isEnabled: data.isEnabled === null ? false : data.isEnabled,
    },
  };
};

export default {
  [CREATE]: createOne(CREATE_ONE_QUERY, parseResponse, parseRequestParams),
  [DELETE]: deleteOne(DELETE_ONE_QUERY),
  [GET_LIST]: getList(LIST_QUERY),
  [GET_ONE]: getOne(GET_ONE_QUERY),
  [UPDATE]: updateOne(UPDATE_ONE_QUERY, parseResponse, updateParseParams),
};
